import { ReactComponent as ArticleIcon } from "../../../assets/Icons/Show/Articles.svg";
import { ReactComponent as DashboardIcon } from "../../../assets/Icons/Show/Dashboard.svg";
import { ReactComponent as BenefitsIcon } from "../../../assets/Icons/Show/Benefits.svg";
import { ReactComponent as MessageIcon } from "../../../assets/Icons/Show/Message.svg";
import { ReactComponent as EventsIcon } from "../../../assets/Icons/Show/Events.svg";
import { ReactComponent as ArrowFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import { ReactComponent as BristolMyersSquibbLogo } from "../../../assets/Icons/OurPartners/BristolMyersSquibb.svg";
import { ReactComponent as AstraZenecaLogo } from "../../../assets/Icons/OurPartners/AstraZeneca.svg";
import { ReactComponent as MSDLogo } from "../../../assets/Icons/OurPartners/MSD.svg";
import { ReactComponent as RocheLogo } from "../../../assets/Icons/OurPartners/Roche.svg";
import { ReactComponent as TakedaLogo } from "../../../assets/Icons/OurPartners/Takeda.svg";
import { BoxArrowLeft } from "react-bootstrap-icons";
import style from "./Navigation.module.scss";
import React from "react";

type NavPath =
  | "ADMIN"
  | "AGREEMENT"
  | "ARTICLES"
  | "CANCERS"
  | "CARD_CODES"
  | "CATEGORIES"
  | "CHANGES"
  | "COMMENT"
  | "COMMENT_EN"
  | "COMPANIES"
  | "COMPANY"
  | "COMPANY_EN"
  | "DASHBOARD"
  | "DASHBOARD_EN"
  | "DEPARTMENT"
  | "DOCTOR"
  | "EVENTS"
  | "FEED"
  | "FEED_EN"
  | "HELP"
  | "LOYALTY_PROGRAM"
  | "LOYALTY_PROGRAM_EN"
  | "ME"
  | "MEDIA"
  | "MESSAGES"
  // zamiana Messengera na inny system
  // https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76
  // | "MESSENGER"
  | "PAGES"
  | "POLLS"
  | "PROFILE"
  | "SERVICE"
  | "SERVICE_EN"
  | "SETTINGS"
  | "SPONSOR"
  | "TRANSLATIONS"
  | "USERS"
  | "VERIFICATION"
  | "VERIFICATION_EN"
  | "EDIT_LANDING_FOOTER";

type NavPathData = {
  [key in NavPath]: string;
};

export interface NavigationLinkType {
  path: string;
  path_en?: string;
  label: string;
  Icon?: React.ReactNode;
  active?: boolean;
}

interface OurPartnersData {
  name?: string;
  Logo: JSX.Element;
}

export const NAV_PATH: NavPathData = {
  ADMIN: "admin",
  AGREEMENT: "regulaminy",
  ARTICLES: "baza-wiedzy",
  CANCERS: "nowotwory",
  CARD_CODES: "karty-kodow",
  CATEGORIES: "kategorie",
  CHANGES: "zmiany",
  COMMENT: "komentarz",
  COMMENT_EN: "comment",
  COMPANIES: "firmy",
  COMPANY: "firma",
  COMPANY_EN: "company",
  DASHBOARD: "pulpit",
  DASHBOARD_EN: "dashboard",
  DEPARTMENT: "oddzial",
  DOCTOR: "lekarz",
  EVENTS: "wydarzenia",
  FEED: "spolecznosc",
  FEED_EN: "feed",
  HELP: "pomoc",
  LOYALTY_PROGRAM: "benefity",
  LOYALTY_PROGRAM_EN: "loyalty-program",
  ME: "ja",
  MEDIA: "media",
  MESSAGES: "wiadomosci",
  // zamiana Messengera na inny system
  // https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76
  // MESSENGER: "messenger",
  PAGES: "strony",
  POLLS: "ankiety",
  PROFILE: "profil",
  SERVICE: "usluga",
  SERVICE_EN: "service",
  SETTINGS: "ustawienia",
  SPONSOR: "partner",
  TRANSLATIONS: "tlumaczenia",
  USERS: "uzytkownicy",
  VERIFICATION: "weryfikacja",
  VERIFICATION_EN: "verification",
  EDIT_LANDING_FOOTER: "stopka-nawigacyjna"
};

export const dashboardLink: NavigationLinkType = {
  path: `/${NAV_PATH.DASHBOARD}`,
  path_en: `/${NAV_PATH.DASHBOARD_EN}`,
  label: "dashboard",
  Icon: <DashboardIcon className={`me-2 ${style.tiles}`} />,
};

export const logOutLink: NavigationLinkType = {
  path: `/${NAV_PATH.DASHBOARD}`,
  label: "logOut",
  Icon: <BoxArrowLeft size={28} className={`me-2 ${style.logOut}`} />,
  active: false,
};

export const navigationLinks: NavigationLinkType[] = [
  {
    path: `/${NAV_PATH.ARTICLES}`,
    label: "articles",
    Icon: <ArticleIcon className={`me-2 ${style.article}`} />,
  },
  {
    path: `/${NAV_PATH.LOYALTY_PROGRAM}`,
    path_en: `/${NAV_PATH.LOYALTY_PROGRAM_EN}`,
    label: "loyaltyProgram",
    Icon: <BenefitsIcon className={`me-2 ${style.benefits}`} />,
  },
  {
    path: `/${NAV_PATH.FEED}`,
    path_en: `/${NAV_PATH.FEED_EN}`,
    label: "feed",
    Icon: <MessageIcon className={`me-2 ${style.message}`} />,
  },
  {
    path: `/${NAV_PATH.EVENTS}`,
    label: "events",
    Icon: <EventsIcon className={`me-2 ${style.events}`} />,
  },
];

export const doctorLinks: NavigationLinkType[] = [
  {
    path: `/${NAV_PATH.DOCTOR}/${NAV_PATH.ME}`,
    label: "doctorProfile",
  },
];

// zamiana Messengera na inny system
// https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76
// export const callCenterLinks: NavigationLinkType[] = [
//   {
//     path: `/${NAV_PATH.ADMIN}/${NAV_PATH.MESSENGER}`,
//     label: "messenger",
//   },
// ];

export const administrationLinks: NavigationLinkType[] = [
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.CHANGES}`,
    label: "changes",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.POLLS}`,
    label: "polls",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.ARTICLES}`,
    label: "articles",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.COMPANIES}`,
    label: "companies",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.CARD_CODES}`,
    label: "cardCodes",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.CATEGORIES}`,
    label: "categories",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.MEDIA}`,
    label: "media",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.CANCERS}`,
    label: "cancers",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.AGREEMENT}`,
    label: "agreement",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.FEED}`,
    label: "feed",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.PAGES}`,
    label: "pages",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.TRANSLATIONS}/1`,
    label: "translations",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.MESSAGES}`,
    label: "messages",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.SETTINGS}`,
    label: "settings",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.USERS}`,
    label: "users",
  },
  {
    path: `/${NAV_PATH.ADMIN}/${NAV_PATH.EDIT_LANDING_FOOTER}`,
    label: "landingPageFooter"
  },
];

const ONKOMAPA =
  process.env?.REACT_APP_EXTERNAL_LINK_ONKOMAPA ??
  (process.env?.REACT_APP_MODE === "dev"
    ? "https://dev.onkomapa.pl"
    : "https://onkomapa.pl");
const ONKOSKANER =
  process.env?.REACT_APP_EXTERNAL_LINK_ONKOSKANER ??
  (process.env?.REACT_APP_MODE === "dev"
    ? "https://dev.onkoskaner.pl"
    : "https://onkoskaner.pl");
const ONKOINDEX =
  process.env?.REACT_APP_EXTERNAL_LINK_ONKOINDEX ??
  (process.env?.REACT_APP_MODE === "dev"
    ? "https://dev.oncoindex.org/pl"
    : "https://oncoindex.org/pl/polska");
const ONKOSNAJPER =
  process.env?.REACT_APP_EXTERNAL_LINK_ONKOSNAJPER ??
  (process.env?.REACT_APP_MODE === "dev"
    ? "https://dev.onkosnajper.pl"
    : "https://prostowraka.pl/onkosnajper");
const ONKOZBIORKA =
  process.env?.REACT_APP_EXTERNAL_LINK_ONKOZBIORKA ??
  (process.env?.REACT_APP_MODE === "dev"
    ? "https://dev.onkozbiorka.pl"
    : "https://onkozbiorka.pl");

export const onkoLinks: NavigationLinkType[] = [
  {
    path: ONKOZBIORKA,
    label: "Zbieraj 1,5% podatku",
    Icon: <ArrowFromSquare className={`me-2 ${style.arrowFromSquare}`} />,
  },
  {
    path: ONKOSKANER,
    label: "Znajdź najkrótszą kolejkę",
    Icon: <ArrowFromSquare className={`me-2 ${style.arrowFromSquare}`} />,
  },
  {
    path: ONKOINDEX,
    label: "Sprawdź dostępne terapie",
    Icon: <ArrowFromSquare className={`me-2 ${style.arrowFromSquare}`} />,
  },
  {
    path: ONKOMAPA,
    label: "Znajdź ośrodek i lekarza",
    Icon: <ArrowFromSquare className={`me-2 ${style.arrowFromSquare}`} />,
  },
  {
    path: ONKOSNAJPER,
    label: " Sprawdź potrzebne badania",
    Icon: <ArrowFromSquare className={`me-2 ${style.arrowFromSquare}`} />,
  },
];

export const ourPartners: OurPartnersData[] = [
  {
    name: "Takeda",
    Logo: <TakedaLogo height={24} width={86} />,
  },
  {
    name: "Roche",
    Logo: <RocheLogo height={24} />,
  },
  {
    name: "MSD Sharp & Dohme GmbH",
    Logo: <MSDLogo height={24} />,
  },
  {
    name: "AstraZeneca",
    Logo: <AstraZenecaLogo width={148} height={44} />,
  },
];
