import React from "react";
import WidgetWithButton from "components/molecules/WidgetWithButton/WidgetWithButton";
import GuardiansAndPatientsPanel from "components/organisms/GuardiansAndPatientsPanel/GuardiansAndPatientsPanel";
import PatientsOnkorallies from "components/organisms/PatientsOnkorallies/PatientsOnkorallies";
import UserCancerInfo from "components/organisms/UserCancerInfo/UserCancerInfo";
import DoctorRatings from "components/organisms/DoctorRatings/DoctorRatings";
import { useSelector } from "react-redux";
import { UserRole, WidgetSetting } from "redux/Slice/user-slice";
import { RootState } from "redux/store/index";
// import { useGetDoctorsQuery } from "../../redux/services/doctorApi";
// import { useGetDetailedFacilitiesQuery } from "redux/services/detailedFacilitiesApi";
import { useGetMeQuery } from "redux/services/meApi";
import { useGetPagesQuery } from "redux/services/pagesApi";
import { useGetFacilityQuery } from "redux/services/facilitiesApi";
import { useGetCancerQuery } from "redux/services/cancerApi";
import { useGetCurrentDoctorQuery } from "../../redux/services/doctorApi";
import { useGetDoctorReviewStatsQuery } from "redux/services/statsApi";
// import { useGetProvincesQuery } from "redux/services/provincesApi";
import { useGetSFContactQuery } from "redux/services/salesforceApi";
import { DoctorReviewDashboard } from "components/organisms/DoctorReviewDashboard/DoctorReviewDashboard";
import { LoyaltyProgramServiceMyCode } from "components/organisms/LoyaltyProgramServiceMyCode/LoyaltyProgramServiceMyCode";
import { LatestServiceContent } from "../../components/organisms/LatestServiceContent/LatestServiceContent";
import WidgetShortestQueue from "../../components/organisms/WidgetShortestQueue/WidgetShortestQueue";
// import RecommendedDoctors from "../../components/organisms/RecommendedDoctors/RecommendedDoctors";
// import RecommendedFacilities from "../../components/organisms/RecommendedFacilities/RecommendedFacilities";
import RatingsAndReviewsSource from "../../components/organisms/RatingsAndReviewsSource/RatingsAndReviewsSource";
import { LoyaltyProgramServiceMyCompany } from "components/organisms/LoyaltyProgramServiceMyCompany/LoyaltyProgramServiceMyCompany";
import { isRoleInUse } from "Helpers/Roles/isRoleInUse";
import TargetedTreatment from "components/organisms/TargetedTreatment/TargetedTreatment";
import { ObservedOncocollections } from "../../components/organisms/ObservedOncocollections/ObservedOncocollections";
import IncomingEvents from "components/organisms/IncomingEvents/IncomingEvents";
import ArticleWidget from "components/organisms/ArticleWidget/ArticleWidget";
import { useTranslation } from "react-i18next";
import { useGetSystemSettingsQuery } from "redux/services/systemSettingsApi";
import { LastPostWidget } from "../../components/organisms/LastPostWidget/LastPostWidget";
import { Oncocollections } from "../../components/organisms/Oncocollections/Oncocollections";
import { ONKO_URL_ADD_ONKOCOLLECTION } from "../urls";

// interface FormValues {
//   rating?: number;
//   specializationIds?: number;
//   provinceId?: number;
//   fieldName?: string;
//   cityId?: number;
// }

export const useWidgets = (
  widgetsData: WidgetSetting[],
  updatePoll: (roleName: string) => void
) => {
  // const [recommendedFacilitiesFilter, setRecommendedFacilitiesFilter] =
  //   React.useState<FormValues | null>(null);
  // const [recommendedDoctorsFilter, setRecommendedDoctorsFilter] =
  //   React.useState<FormValues | null>(null);
  const DEFAULT_CANCER_ID = "0";
  const DEFAULT_FACILITY_ID = 0;

  const { t } = useTranslation();

  // const handleFacilitiesFilterChange = (newFilterData: FormValues) => {
  //   setRecommendedFacilitiesFilter(newFilterData);
  // };

  // const handleDoctorsFilterChange = (newFilterData: FormValues) => {
  //   setRecommendedDoctorsFilter(newFilterData);
  // };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };
  const { authUser } = useSelector((state: RootState) => state.user);
  const rolesArray = authUser?.roles.map((roles: UserRole) => {
    return roles?.role?.role;
  });

  const settings = [...widgetsData]
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .filter(
      (wd: WidgetsData) =>
        wd.isActive !== false &&
        (Array.isArray(wd.forRole)
          ? wd.forRole.some((role) => rolesArray.includes(role))
          : rolesArray.includes(wd.forRole))
    )
    .map((x) => x.id);

  const deSlugify = (slug: string) => {
    if (slug) {
      const text = slug.replaceAll("_", " ");
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    } else {
      return "";
    }
  };

  const { data: meData } = useGetMeQuery({});
  const { data: sfContactData } = useGetSFContactQuery({});
  // const { data: provincesData } = useGetProvincesQuery({});
  // const provinceNameFromMe = meData?.data?.settings?.MailingState;
  // const provinceNameFromSfContact = sfContactData?.data?.MailingState;
  // const provinceIdFromMe = provincesData?.data?.find(
  //   ({ name }: { name: string }) => name === provinceNameFromMe
  // )?.id;
  // const provinceIdFromSfContact = provincesData?.data?.find(
  //   ({ name }: { name: string }) => name === provinceNameFromSfContact
  // )?.id;
  // const defaultProvinceId = provinceIdFromMe ?? provinceIdFromSfContact ?? 7;

  // const { data: recommendedDoctorsData } = useGetDoctorsQuery({
  //   orderBy: "totalReviews",
  //   provinceId:
  //     recommendedDoctorsFilter?.provinceId?.toString() ??
  //     defaultProvinceId.toString(),
  //   limit: "3",
  //   rating: recommendedDoctorsFilter?.rating?.toFixed(0) ?? "5",
  //   specializationIds:
  //     recommendedDoctorsFilter?.specializationIds?.toString() ?? "1",
  //   systemId: "2",
  // });

  // const { data: recommendedFacilitiesData, refetch } =
  //   useGetDetailedFacilitiesQuery({
  //     limit: "3",
  //     sortBy: "rating",
  //     provinceId:
  //       recommendedFacilitiesFilter?.provinceId?.toString() ??
  //       defaultProvinceId.toString(),
  //     sortOrder: "DESC",
  //     queueId: "1",
  //     systemId: "2",
  //     rating: recommendedFacilitiesFilter?.rating?.toFixed(0) ?? "4",
  //     cityId:
  //       recommendedFacilitiesFilter?.cityId?.toString() ??
  //       meData?.data?.settings?.mailingCityId,
  //   });

  const { data: systemSettingsData } = useGetSystemSettingsQuery({});

  const myCancerSystemSettings = systemSettingsData?.data?.find(
    ({ context, type }: { context: string; type: string }) =>
      context === "widgetMyCancer" && type === "faq"
  );

  // const resetFacilitesFiltersAndRefetch = () => {
  //   setRecommendedFacilitiesFilter({
  //     rating: 5,
  //     specializationIds: 1,
  //     provinceId: defaultProvinceId,
  //   });
  //   refetch();
  // };

  // const resetDoctorsFiltersAndRefetch = () => {
  //   setRecommendedDoctorsFilter({
  //     rating: 5,
  //     specializationIds: 1,
  //     provinceId: defaultProvinceId,
  //   });
  //   refetch();
  // };

  const { data: pagesData } = useGetPagesQuery({
    categoryIds: myCancerSystemSettings?.value,
    cancerId: authUser?.settings?.ID_nowotworu_HUB__c,
    categorySlugs: authUser?.settings?.Etap_leczenia__c,
  });

  const facilityIDFromMeSettings = meData?.settings?.facilityIds__c;
  const FACILITY_ID_FROM_ME_SETTINGS = facilityIDFromMeSettings?.[0];
  const { data: facilityData } = useGetFacilityQuery(
    { id: FACILITY_ID_FROM_ME_SETTINGS },
    { skip: !FACILITY_ID_FROM_ME_SETTINGS }
  );

  const {
    data: cancerData,
    isLoading: isCancerLoading,
    isFetching: isCancerFetching,
  } = useGetCancerQuery({
    id: authUser?.settings?.ID_nowotworu_HUB__c
      ? authUser?.settings?.ID_nowotworu_HUB__c
      : DEFAULT_CANCER_ID,
  });

  const isComponentLoading = isCancerLoading || isCancerFetching;
  const { data: doctorData } = useGetCurrentDoctorQuery({});
  const doctorId = doctorData?.data?.id
    ? doctorData.data.id.toString()
    : undefined;
  const { data: doctorReviewStatsData } = useGetDoctorReviewStatsQuery(
    { doctorId },
    { skip: !doctorId }
  );

  const contactData = sfContactData?.data;

  const shouldRenderObservedOncocollections =
    sfContactData?.data?.Cel_zbi_rki_ostatnio_wspartej__c ||
    sfContactData?.data?.Tytu_zbi_rki_ostatnio_wspartej__c ||
    sfContactData?.data?.Dost_pne_rodki_ostatnio_wspartej__c ||
    sfContactData?.data?.Link_do_zbi_rki_ostatnio_wspartej__c;

  const shouldRenderOncocollections =
    contactData?.Wskazanie_na_skarbonke__r?.Cel_zbi_rki__c ||
    contactData?.Wskazanie_na_skarbonke__r?.Tytul_zbiorki__c ||
    contactData?.Wskazanie_na_skarbonke__r?.Dost_pne_rodki__c ||
    contactData?.Wskazanie_na_skarbonke__r?.Adres__c;

  //eslint-disable-next-line
  return settings.map((id, key) => {
    switch (id) {
      case 3:
        //Panel podopiecznych
        return (
          <GuardiansAndPatientsPanel
            type="patient"
            key={key}
            text={t("widget_guardians_and_patients_panel_patients_p")}
          />
        );
      case 4:
        //Panel opiekuna
        return (
          <GuardiansAndPatientsPanel
            type="guardian"
            key={key}
            text={t("widget_guardians_and_patients_panel_guardian_p")}
          />
        );
      case 2:
        //Ważne informacje
        return authUser?.settings?.pollID__c &&
          isRoleInUse("patient", authUser) ? (
          <React.Fragment key={key}>
            {cancerData?.data?.name || facilityData?.data?.name ? (
              <UserCancerInfo
                data={pagesData?.data}
                updatePoll={updatePoll}
                cancerName={cancerData?.data?.name}
                roleName="patient"
                placeOfTreatment={facilityData?.data?.name}
                treatmentStatus={deSlugify(
                  authUser?.settings?.Etap_leczenia__c ?? ""
                )}
                isLoading={isComponentLoading}
              />
            ) : (
              <WidgetWithButton
                title={t("widget_your_cancer_title")}
                text={t("widget_your_cancer_p1")}
                buttonVariant="primary"
                buttonText={t("widget_your_cancer_button1")}
                buttonAction={() => updatePoll("patient")}
              />
            )}
          </React.Fragment>
        ) : (
          <WidgetWithButton
            title={t("widget_your_cancer_title")}
            key={key}
            text={t("widget_your_cancer_p2")}
            buttonVariant="secondary"
            buttonText={t("widget_your_cancer_button2")}
            buttonAction={() => updatePoll("patient")}
          />
        );
      case 5:
        //Onkozbiórki podopiecznych
        return <PatientsOnkorallies key={key} />;

      case 1:
        //Onkozbiórka
        if (!shouldRenderOncocollections) {
          return (
            <WidgetWithButton
              title={t("widget_oncocollection_title")}
              key={key}
              text={t("widget_oncocollection_p")}
              buttonVariant="primary"
              buttonText={t("widget_oncocollection_button")}
              buttonAction={() => openInNewTab(ONKO_URL_ADD_ONKOCOLLECTION)}
            />
          );
        }
        return (
          <Oncocollections key={key} name={t("widget_oncocollection_title")} />
        );

      case 6:
        //Oceny
        return (
          <DoctorRatings
            key={key}
            data={doctorReviewStatsData?.data}
            doctorId={doctorData?.data?.id}
          />
        );

      case 9:
        //Polecani lekarze
        return (
          // <RecommendedDoctors
          //   key={key}
          //   data={recommendedDoctorsData?.data}
          //   onFilterChange={handleDoctorsFilterChange}
          //   externalResetFilters={resetDoctorsFiltersAndRefetch}
          // />
          <></>
        );

      case 10:
        //Polecane ośrodki leczenia
        return (
          // <RecommendedFacilities
          //   key={key}
          //   data={recommendedFacilitiesData?.data}
          //   refetch={refetch}
          //   onFilterChange={handleFacilitiesFilterChange}
          //   externalResetFilters={resetFacilitesFiltersAndRefetch}
          // />
          <></>
        );

      case 7:
        //Recenzje doktora
        return (
          <DoctorReviewDashboard
            key={key}
            doctorId={doctorData?.data?.id}
            userId={authUser.id}
            profileAccept={doctorData?.data?.status === "ACCEPTED"}
          />
        );

      case 11:
        //Najnowsze benefity
        return (
          <LatestServiceContent
            key={key}
            name={t("widget_loyalty_newest_benefits_title")}
            latest={true}
            classNameContainer={`p-4 containerWithShadow `}
            profiling={true}
          />
        );

      case 8:
        //Twoje wybrane benefity
        return (
          <LoyaltyProgramServiceMyCode
            key={key}
            name={t("widget_loyalty_your_benefits_title")}
          />
        );

      case 12:
        //Najkrótsza kolejka
        return <WidgetShortestQueue key={key} />;

      case 13:
        //Twoje firmy
        return (
          <LoyaltyProgramServiceMyCompany
            key={key}
            name={t("widget_loyalty_your_company_title")}
          />
        );

      case 14:
        //Źródło ocen i opinii
        return (
          <RatingsAndReviewsSource
            key={key}
            doctorId={doctorData?.data?.id}
            doctorDegree={doctorData?.data?.degree?.name}
            doctorFirstName={doctorData?.data?.firstName}
            doctorLastName={doctorData?.data?.lastName}
          />
        );

      case 15:
        //Obserwowane onkozbiórki
        if (!shouldRenderObservedOncocollections) {
          return null;
        }
        return (
          <ObservedOncocollections
            key={key}
            name={t("widget_observed_oncocollections_title")}
          />
        );

      case 18:
        //Leczenie celowane
        return <TargetedTreatment key={key} />;

      case 16:
        //Polecane wydarzenia
        return <IncomingEvents key={key} />;

      case 17:
        //Polecane artykuły
        return <ArticleWidget key={key} />;

      case 19:
        //Najnowszy post
        return <LastPostWidget key={key} />;

      default:
        return null;
    }
  });
};
