import React from "react";
import { ReactComponent as TriangleExclamation } from "assets/Icons/TriangleExclamation.svg";

interface Props {
  label: string;
  className?: string;
  iconDisplay?: boolean;
}

export const PopoverLine = (props: Props) => {
  const { label, className, iconDisplay = true } = props; 

  return (
    <div className={"d-flex justify-content-end"}>
      <span className={`pe-3 text-dark-blue ${className}`}>
        {label}
      </span>
      {iconDisplay && <TriangleExclamation />} 
    </div>
  );
};
