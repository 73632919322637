import { useFormContext, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import PollAttachmentDropzone from "components/atoms/PollAttachmentDropzone/PollAttachmentDropzone";
import { useState, useEffect } from "react";
import DisplayFile from "components/atoms/DisplayFile/DisplayFile";
import styles from "./AttachmentsInput.module.scss";
import { useGetAttachmentsQuery } from "redux/services/attachmentsApi";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

const AttachmentInput: React.FC<InputComponentProps> = ({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
  setPollDefaultValueLoadingState,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const {
    setValue,
    control,
    formState: { isSubmitted, errors },
  } = useFormContext();

  const [fetchedData, setFetchedData] = useState<FileData[] | null>(null);
  const { data: queryResult } = useGetAttachmentsQuery(
    { ids: defaultValue },
    { skip: !defaultValue }
  );
  console.log(queryResult);

  useEffect(() => {
    if (defaultValue) {
      setFetchedData(queryResult?.data);
    } else {
      setFetchedData(null);
    }
  }, [defaultValue, queryResult?.data]);

  useEffect(() => {
    if (fetchedData || !queryResult?.data) {
      setPollDefaultValueLoadingState((prevState) => ({
        ...prevState,
        isAttachmentsInputLoading: false,
      }));
    }
    //eslint-disable-next-line
  }, [fetchedData]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        if (queryResult?.data) {
          const fetchedImages = (await Promise.allSettled(
            queryResult?.data?.map(async (file: FileData) => {
              try {
                const response = await fetch(file.accessUrl);
                if (!response.ok) {
                  throw new Error(
                    `Failed to fetch image from ${file.accessUrl}`
                  );
                }
                const blob = await response.blob();
                const fileObj = new File([blob], file.filename, {
                  type: blob.type,
                });
                return fileObj;
              } catch (error) {
                console.error(`Error fetching image ${file.accessUrl}:`, error);
                return null;
              }
            }) ?? []
          )) as {
            status: "fulfilled" | "rejected";
            value: File;
          }[];

          const prevFiles = fetchedImages
            .filter((fetchedImage) => fetchedImage.status === "fulfilled")
            // eslint-disable-next-line array-callback-return
            .map((fetchedImage) => fetchedImage.value);

          setFiles(prevFiles);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [queryResult?.data]);

  useEffect(() => {
    setValue(question?.fieldName, files);
  }, [files, setValue, question?.fieldName]);

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";
  const isRequired = JSON.parse(validationMeta?.value || "{}").isRequired;

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <div className="d-flex flex-column">
        <div className={`d-flex flex-wrap ${styles.previews}`}>
          {fetchedData?.map((file: FileData, i: number) => (
            <div
              key={i}
              className="d-flex justify-content-center items-align-center flex-column mt-2 mb-3"
            >
              <DisplayFile file={file} />
              <a
                href={file.accessUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                Podgląd
              </a>
            </div>
          )) ??
            (readOnly && <p>Nie załączono pliku.</p>)}
        </div>
        {!readOnly && (
          <>
            <Controller
              control={control}
              name={question?.fieldName}
              rules={{
                required: {
                  value: isRequired,
                  message: "To pole jest wymagane.",
                },
              }}
              render={({ field }) => (
                <>
                  <PollAttachmentDropzone
                    files={files}
                    setFiles={setFiles}
                    isError={
                      isRequired &&
                      !!errors?.[question?.fieldName] &&
                      files.length === 0
                    }
                  />
                  {isRequired &&
                    !!errors?.[question?.fieldName] &&
                    files.length === 0 && (
                      <p className="mt-1 text-danger">
                        {errors?.[question?.fieldName]?.message as string}
                      </p>
                    )}
                </>
              )}
            />
          </>
        )}

        {(nextPageClicked || isSubmitted) &&
          isRequired &&
          !(files.length > 0 || (fetchedData?.length ?? 0) > 0) && (
            <p className="text-validation">{requiredMessage}</p>
          )}
      </div>

      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
};

export default AttachmentInput;
