import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import LandingHeader from "components/molecules/LandingHeader/LandingHeader";

import styles from "./Landing.module.scss";
import { NAV_PATH } from "../Navigation/navigationData";
import LandingFooter from "components/molecules/LandingFooter/LandingFooter";

export const domain = `${window.location.protocol}//${window.location.host}`;

export default function Landing({
  isContent = true,
  isButton = true,
}: {
  isContent?: boolean;
  isButton?: boolean;
}) {
  const [systemSetttings, setSystemSetttings] = useState<LandingContextProps[]>(
    []
  );
  const [landingCover, setLandingCover] = useState<LandingCoverData | null>(
    null
  );
  const baseUrl = process.env.REACT_APP_EXTERNAL_LINK_STATIC;
  
  useEffect(() => {
    (async () => {
      if (!baseUrl) return;

      const url = `${baseUrl}/system/settings.json`;
      const response = await fetch(url);

      if (!response) {
        throw new Error("Failed to fetch landing cover.");
      }

      const data: LandingContextProps[] = await response.json();

      setSystemSetttings(data);
    })();
  }, [baseUrl]);

  useEffect(() => {
    const landingCover = systemSetttings?.find(
      (obj: LandingContextProps) => obj.context === "landing_cover"
    )?.value;

    if (landingCover) {
      setLandingCover(JSON.parse(landingCover));
    }
  }, [systemSetttings]);

  if (systemSetttings === null) {
    return <Spinner animation="border" variant="info" />;
  }

  const loginHandler = () => {
    let pathname = window.location.pathname;
    let search = window.location.search;
    if (pathname === "/") {
      pathname = `/${NAV_PATH.DASHBOARD}`;
      search = "";
    }
    const redirectUrl = `${domain}/api/v1/login?redirectUrl=${domain}${pathname}${search}`;
    window.location.replace(redirectUrl);
  };

  const signUpHandler = () => {
    let pathname = window.location.pathname;
    let search = window.location.search;
    if (pathname === "/") {
      pathname = `/${NAV_PATH.DASHBOARD}`;
      search = "";
    }
    const domain = `${window.location.protocol}//${window.location.host}`;
    const redirectUrl = `${domain}/api/v1/register?redirectUrl=${domain}${pathname}${search}`;
    window.location.replace(redirectUrl);
  };

  const landingHeaderH2 =
    systemSetttings?.find(
      (obj: LandingContextProps) => obj.context === "landing_header_h2"
    )?.value || "";
  const landingHeaderH3 =
    systemSetttings?.find(
      (obj: LandingContextProps) => obj.context === "landing_header_h3"
    )?.value || "";
  const landingContent =
    systemSetttings
      ?.find((obj: LandingContextProps) => obj.context === "landing_content")
      ?.value.replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">") || "";

  const landingCoverUrl = landingCover?.accessUrl;

  return (
    <div className={`${styles.wrapper}`}>
      <LandingHeader />
      <div className="position-relative">
        <div
          className={`${styles.hero}`}
          style={{ backgroundImage: `url(${landingCoverUrl})` }}
        ></div>

        <div className={`${styles.container}`}>
          <div className={` ps-3 pe-3 ${styles.heroText}`}>
            <h1 className={`${styles.header} mb-3 fs-16`}>
              <span className={`fw-700`}>{landingHeaderH2}</span>
              <div className={`${styles.spacer}`}></div>
              <span className={`fw-600`}>{landingHeaderH3}</span>
            </h1>
            {isButton && (
              <div>
                <Button
                  className={`me-3 col-12 col-md-5 d-block d-md-inline mb-3 mb-md-0 w-full ${styles.loginBtn}`}
                  onClick={loginHandler}
                >
                  Zaloguj się
                </Button>
                <Button
                  className={`me-3 col-12 col-md-5 d-block d-md-inline ${styles.signupBtn}`}
                  onClick={signUpHandler}
                >
                  Załóż konto
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isContent && (
        <div
        className={`pt-5 pb-5 ps-3 pe-3 text-center ${styles.main} ${styles.container}`}
        dangerouslySetInnerHTML={{ __html: landingContent || "" }}
        />
      )}
      <LandingFooter/>
    </div>
  );
}
