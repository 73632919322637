import TextInput from "components/atoms/TextInput/TextInput";
import { Button, Form, Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useChangePasswordWithAdminMutation } from "redux/services/usersApi";
import { useTranslation } from "react-i18next";
import styles from "../ChangePasswordModal/ChangePasswordModal.module.scss";

interface PasswordChangeResponse {
  data?: {
    error: string;
    message: string;
    statusCode?: number;
    meta: {
      status?: string;
    };
  };
}
const ChangePasswordWithAdminModal = ({
  userId,
  show,
  setShow,
}: ChangePasswordModalProps) => {
  const [changePasswordWithAdmin] = useChangePasswordWithAdminMutation();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ChangePasswordProps>({
    defaultValues: {
      newPassword: "",
      repeatNewPassword: "",
    },
  });

  const submitHandler: SubmitHandler<ChangePasswordProps> = async (data) => {
    const passwordChanged = (await changePasswordWithAdmin({
      userId,
      data: {
        newPassword: data.newPassword,
      },
    })) as PasswordChangeResponse;
    if (passwordChanged?.data?.meta?.status === "success") {
      toast.success(t("toast_changePasswordModal_success"));
      setShow(false);
      reset();
      clearErrors("currentPassword");
    } else if (!!passwordChanged?.data?.error) {
      const errorStatusCode = passwordChanged?.data?.statusCode;
      if (errorStatusCode === 500) {
        toast.error(t("toast_changePasswordModal_error_500"));
      }
      if (errorStatusCode === 400) {
        toast.error(t("toast_changePasswordModal_error"));
        setError("currentPassword", {
          message: t("changePasswordModal_currentPassword_error"),
        });
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="border-0 d-flex flex-row justify-content-between px-4 pb-0 pt-4"
          >
            <p className={`fw-600 fs-22 text-dark-blue m-0 ${styles.title}`}>
              Zmiana hasła
            </p>
          </Modal.Header>
          <Modal.Body className="align-items-center justify-content-center p-4">
            <div className="w-100 p-4">
              <div className="d-flex flex-column gap-2 w-100">
                <TextInput
                  label="Nowe hasło"
                  register={register("newPassword", {
                    required: { value: true, message: "Pole wymagane" },
                    pattern: {
                      value:
                        /(?=^.{8,}$)(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                      message:
                        "Hasło musi się skłądać z przynajmniej 8 znaków i zawierać 1 dużą literę, jedną cyfrę oraz jeden znak specjalny.",
                    },
                  })}
                  className="fs-16 fw-400 flex-column w-100 mb-3"
                  labelClassName="fw-400 fs-16 text-dark-blue"
                  type="password"
                  hintText="Hasło musi się skłądać z przynajmniej 8 znaków i zawierać 1 dużą literę, jedną cyfrę oraz jeden znak specjalny."
                  error={errors?.newPassword?.message}
                />
                <TextInput
                  label="Powtórz hasło"
                  register={register("repeatNewPassword", {
                    validate: (value) => value === getValues("newPassword"),
                  })}
                  className="fs-16 fw-400 flex-column w-100"
                  labelClassName="fw-400 fs-16 text-dark-blue"
                  type="password"
                  error={
                    errors.repeatNewPassword ? "Hasła muszą się pokrywać" : ""
                  }
                />
              </div>
              <div className="d-flex flex-row justify-content-end mt-5">
                <Button
                  variant="outline-dark"
                  className="text-uppercase me-3"
                  onClick={() => setShow(!show)}
                >
                  Anuluj
                </Button>
                <Button
                  variant="primary"
                  className="text-uppercase"
                  type="submit"
                  onClick={handleSubmit(submitHandler)}
                >
                  Aktualizuj
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Form>
    </>
  );
};

export default ChangePasswordWithAdminModal;
