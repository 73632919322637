import { useEffect, useState } from "react";
import Select from "react-select";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { doctorApi } from "redux/services/doctorApi";
import { cancersApi } from "redux/services/cancerApi";
import { citiesApi } from "redux/services/citiesApi";
import { provincesApi } from "redux/services/provincesApi";
import { facilitiesApi } from "redux/services/facilitiesApi";
import { doctorSpecializationApi } from "redux/services/doctorSpecializationApi";
import { Form } from "react-bootstrap";
import {
  customStyles,
  DropdownIndicator,
} from "../../../../styles/reactSelectCustomStyles";
import {
  City,
  Province,
} from "components/molecules/LoyaltyProgramServiceArticle/types";
import { slugify } from "Helpers/slugify";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

export default function DatalistInput({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
  setPollDefaultValueLoadingState,
}: InputComponentProps) {
  const {
    register,
    formState: { errors, isSubmitted },
    setValue,
    watch,
  } = useFormContext();
  const [options, setOptions] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { data: doctorsData } = doctorApi.useGetDoctorsQuery({
    offset: "0",
    limit: "100",
    nameLike: searchTerm,
  });
  const { data: cancersData } = cancersApi.useGetCancersQuery({
    offset: "0",
    limit: "0",
  });
  const { data: citiesData } = citiesApi.useGetCitiesQuery({
    offset: "0",
    limit: "0",
    q: searchTerm,
  });
  const { data: provincesData } = provincesApi.useGetProvincesQuery({
    offset: "0",
    limit: "0",
  });
  const { data: facilitiesData } = facilitiesApi.useGetFacilitiesQuery({
    offset: "0",
    limit: "0",
    sortBy: "ASC",
    nameLike: searchTerm,
  });
  const { data: doctorSpecializationData } =
    doctorSpecializationApi.useGetDoctorSpecializationsQuery({
      offset: "0",
      limit: "0",
    });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (question?.meta.some((meta) => meta?.key === "answers")) {
          const answers = question?.meta.find(
            (meta) => meta.key === "answers"
          )?.value;
          const predefinedOptions = answers?.split("||") || [];
          setOptions(predefinedOptions);
        } else if (question?.meta?.some((meta) => meta.key === "table_name")) {
          const tableName = question?.meta.find(
            (meta) => meta.key === "table_name"
          )?.value;
          let optionsFromAPI: string[] = [];

          switch (tableName) {
            case "doctor":
              if (doctorsData?.data) {
                optionsFromAPI = doctorsData.data.map(
                  (doctor: Doctor) => `${doctor.firstName} ${doctor.lastName}`
                );
              }
              break;
            case "oi_cancer":
              optionsFromAPI =
                cancersData?.data?.map(
                  (cancer: Cancer) => cancer?.name ?? null
                ) ?? [];
              break;
            case "province":
              optionsFromAPI =
                provincesData?.data?.map(
                  (province: Province) => province?.name ?? null
                ) ?? [];
              break;
            case "city":
              optionsFromAPI =
                citiesData?.data?.map((city: City) => city?.name ?? null) ?? [];
              break;
            case "facility":
              optionsFromAPI =
                facilitiesData?.data?.map(
                  (facility: Facility) => facility?.name ?? null
                ) ?? [];
              break;
            case "doctor_specialization":
              optionsFromAPI =
                doctorSpecializationData?.data?.map(
                  (spec: DoctorSpecializationsData) => spec?.name ?? null
                ) ?? [];
              break;
            default:
              break;
          }

          setOptions(optionsFromAPI);
        }
      } catch (e: unknown) {
        console.error(e);
      }
    };

    fetchOptions();
  }, [
    question.meta,
    doctorsData,
    cancersData,
    provincesData,
    citiesData,
    facilitiesData,
    doctorSpecializationData,
  ]);

  useEffect(() => {
    if (options.length > 1) {
      setPollDefaultValueLoadingState((prevState) => ({
        ...prevState,
        isDatalistInputLoading: false,
      }));
    }
    //eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question.fieldName]);

  const handleSelectChange = (selectedOption: SelectOption | null) => {
    const value = selectedOption?.value || "";
    const effectiveValue = areAnswersAsSlug ? slugify(String(value)) : value;
    setValue(question?.fieldName, effectiveValue);
  };

  const handleInputChange = (inputValue: string) => {
    setSearchTerm(inputValue);
  };

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";
  const areAnswersAsSlug = validationMeta
    ? JSON.parse(validationMeta.value)?.areAnswersAsSlug ?? false
    : false;
  const selectOptions = options.map((option) => ({
    value: areAnswersAsSlug ? slugify(option) : option,
    label: option,
  }));

  const watchedValue = areAnswersAsSlug
    ? slugify(watch(question.fieldName, ""))
    : watch(question.fieldName, "");

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <Select
        {...register(question?.fieldName, {
          required: requiredMessage,
        })}
        className="basic-single mt-2 mb-2"
        classNamePrefix="select"
        defaultValue={selectOptions.find(
          (option) => option.value === watchedValue
        )}
        isClearable
        isSearchable
        options={selectOptions}
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        value={selectOptions.find((option) => option?.value === watchedValue)}
        placeholder="Wybierz z listy"
        isDisabled={readOnly}
        styles={customStyles(false)}
        components={{ DropdownIndicator }}
      />
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
}
