import { useParams, useNavigate, Link } from "react-router-dom";
import { useGetLoyaltyProgramsServiceQuery } from "../../../redux/services/loyaltyProgramApi";
import { Container, Row, Col, Image, Stack, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "assets/Icons/Arrow.svg";
import PlaceholderImg from "assets/images/companySmall.png";
import styles from "./LoyaltyProgramServiceContent.module.scss";
import { LoyaltyProgramServiceArticle } from "../../molecules/LoyaltyProgramServiceArticle/LoyaltyProgramServiceArticle";
import { LatestServiceContent } from "../LatestServiceContent/LatestServiceContent";
import { Agreement } from "../Agreement/Agreement";
import { LoyaltyProgramServiceCode } from "../../molecules/LoyaltyProgramServiceCode/LoyaltyProgramServiceCode";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import { getWindowWidth } from "../../../Helpers/getWindowWidth";
import { ViewNotAuthorized } from "../../atoms/ViewNotAuthorized/ViewNotAuthorized";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";

export const LoyaltyProgramServiceContent = () => {
  const { t } = useTranslation();
  const { id: serviceId } = useParams();
  const navigate = useNavigate();
  const [fontSize, setFontSize] = useState(
    getWindowWidth() <= 991 ? "fs-29" : "fs-39"
  );
  const { data: loyaltyProgramServiceData, isLoading } =
    useGetLoyaltyProgramsServiceQuery({ id: Number(serviceId) });
  useEffect(() => {
    function handleResize() {
      if (getWindowWidth() <= 991) {
        setFontSize("fs-29");
      } else {
        setFontSize("fs-39");
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading) {
    return (
      <Container className={"d-flex fs-39 justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }
  if (!loyaltyProgramServiceData || !loyaltyProgramServiceData.data) {
    return (
      <ViewNotAuthorized
        value={t("service_no_accept_view_header")}
        info={t("service_no_accept_view_info")}
      />
    );
  }

  return (
    <Container
      fluid={true}
      className="bg-light"
      key={loyaltyProgramServiceData.data.id}
    >
      <section className={styles.section}>
        <Stack gap={5}>
          <Agreement serviceId={serviceId} />
          <Row>
            <Col
              className={"d-flex align-items-center cursor-pointer p-0 pb-3"}
              onClick={() => navigate(-1)}
            >
              <Arrow
                style={{ transform: "rotate(180deg)" }}
                className={"text-primary"}
              />
              <span className={"ps-2 text-primary"}>
                WRÓĆ DO PRZEGLĄDANIA BENEFITÓW
              </span>
            </Col>
            <Row>
              <div
                className={`${fontSize} fw-semibold ls-3 text-dark-blue mx-0 col-9 d-md-flex align-items-center p-0 pb-2`}
              >
                <span>{loyaltyProgramServiceData.data.name}</span>
              </div>
            </Row>
            <Col className={"d-flex align-items-center p-0"}>
              <Link
                to={`/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${loyaltyProgramServiceData.data.company.id}`}
              >
                <Image
                  fluid={true}
                  className={`p-0 m-0 ${styles.imageLogo}`}
                  roundedCircle
                  src={
                    convertImageIdToUrl(
                      loyaltyProgramServiceData.data.company.imageIds,
                      false,
                      "small.jpeg"
                    ) || PlaceholderImg
                  }
                />
              </Link>
              <Link
                to={`/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${loyaltyProgramServiceData.data.company.id}`}
              >
                <div
                  className={
                    "d-flex align-items-center fs-14 ls-3 fw-semibold text-dark-blue p-0 ps-3"
                  }
                >
                  <span>{loyaltyProgramServiceData.data.company.name}</span>
                </div>
              </Link>
            </Col>
          </Row>

          <Row className={`p-0`}>
            <Col className={"col-12 col-lg-9 p-0 pe-0 pe-lg-3"}>
              <LoyaltyProgramServiceArticle
                data={loyaltyProgramServiceData.data}
              />
            </Col>
            <Col className={`d-none d-lg-block col-3`}>
              <div className={`sticky-top ${styles.serviceCode}`}>
                <LoyaltyProgramServiceCode
                  companyId={loyaltyProgramServiceData.data.company.id}
                  name={loyaltyProgramServiceData.data.name}
                />
              </div>
            </Col>
          </Row>

          <Row className={`${styles.wrapper}`}>
            <LatestServiceContent
              name={"Najnowsze benefity"}
              latest={true}
              currentServiceId={serviceId}
              sorting="news"
            />
          </Row>
        </Stack>
      </section>
    </Container>
  );
};
