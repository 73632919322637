import { useState } from "react";
import styles from "./UserViewTableRow.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import { dateEmbellishment } from "../../../Helpers/dateHelpers";
import { AdminUserRoleEntity } from "../../molecules/AdminUserRoleEntity/AdminUserRoleEntity";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/LargePlus.svg";
import { AdminUserRoleForm } from "../../molecules/AdminUserRoleForm/AdminUserRoleForm";
import PopupModal from "../../molecules/PopupModal/PopupModal";
import {
  usePatchStatusAndDeletedMutation,
  useResetUserPasswordMutation,
  useDeleteUserMutation,
  usePatchActiveUserEmailMutation,
  usePatchStatusMutation,
  usePatchUserSFContactMutation,
} from "../../../redux/services/usersApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";
import { useDeleteGuardianAndPatient } from "Helpers/useDeleteGuardianAndPatient";
import ChangePasswordModal from "components/molecules/ChangePasswordModal/ChangePasswordModal";
import ChangePasswordWithAdminModal from "components/molecules/ChangePasswordWithAdminModal/ChangePasswordWithAdminModal";

interface UserViewTableRowProps {
  data: UsersData;
}
interface SfKeyToDeleteAccount {
  Do_usuni_cia__c?: boolean;
  Do_usuni_cia_all__c?: boolean;
}
const ACCOUNT_TO_BE_DELETED_STATUS = 1;

export const UserViewTableRow = ({ data }: UserViewTableRowProps) => {
  const [showModalStatus, setShowModalStatus] = useState<boolean>(false);
  const [showModalDisabled, setShowModalDisabled] = useState<boolean>(false);
  const [showModalRestart, setShowModalRestart] = useState<boolean>(false);
  const [showModalEnabled, setShowModalEnabled] = useState<boolean>(false);
  const [showModalDead, setShowModalDead] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModalRevertDeletion, setShowModalRevertDeletion] =
    useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] =
    useState<boolean>(false);
  const [showChangeUserPasswordModal, setShowChangeUserPasswordModal] =
    useState<boolean>(false);
  const [showModalEmailActive, setShowModalEmailActive] =
    useState<boolean>(false);

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isActiveRole, setIsActiveRole] = useState<boolean>(false);

  const { t } = useTranslation();
  const [patchSfAccount] = usePatchUserSFContactMutation();
  const [patchStatusAndDeleted] = usePatchStatusAndDeletedMutation();
  const [changeAccountStatus] = usePatchStatusAndDeletedMutation();
  const [patchStatus] = usePatchStatusMutation();
  const [resetUserPassword] = useResetUserPasswordMutation();
  const [deleteAccount] = useDeleteUserMutation();
  const [activeUserEmail] = usePatchActiveUserEmailMutation();

  const deleteGuardianAndPatient = useDeleteGuardianAndPatient();

  const breadcrumbData = [
    {
      name: "Pokaż profil",
      path: `/${NAV_PATH.PROFILE}/${data?.authId}`,
    },
    {
      name: "Restart hasła",
      action: () => setShowModalRestart(true),
    },
    // {
    //   name: "Zmien hasło",
    //   action: () => setShowChangePasswordModal(true),
    // },
    {
      name: "Ustaw hasło",
      action: () => setShowChangeUserPasswordModal(true),
    },
    // do ewentualnego późniejszego zastosowania
    // {
    //   name: data?.enabled === 1 ? "Dezaktywuj (enabled)" : "Aktywuj (enabled)",
    //   action: () => setShowModalEnabled(true),
    // },
    // {
    //   name: data?.status === 1 ? "Dezaktywuj (status)" : "Aktywuj (status)",
    //   action: () => setShowModalStatus(true),
    // },
    {
      name: data?.status === 1 ? "Zablokuj konto" : "Odblokuj konto",
      action: () => setShowModalDisabled(true),
    },
    {
      name: "Zablokuj konto zmarłego",
      action: () => setShowModalDead(true),
    },
    {
      name: "Usuń konto",
      action: () => setShowModalDelete(true),
    },
    {
      name: "Cofnij usunięcie",
      action: () => setShowModalRevertDeletion(true),
    },
    {
      name: "Aktywuj E-mail",
      action: () => setShowModalEmailActive(true),
    },
  ];

  const modalStatusHandler = async () => {
    await patchStatusAndDeleted({
      data: {
        userId: data?.id,
        status: data?.status === 1 ? 0 : 1,
      },
    });
    setShowModalStatus(false);
  };

  const modalDisabledHandler = async () => {
    const hasBeenUpdated = await patchStatus({
      data: {
        userId: data?.id,
        status: data?.status === 10 ? 1 : 10,
      },
    });

    if (
      "data" in hasBeenUpdated &&
      hasBeenUpdated?.data?.meta?.status === "success"
    ) {
      toast.success(
        data.status === 1 ? "Konto zablokowane" : "Konto odblokowane"
      );
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_userViewTableRow_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_userViewTableRow_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_userViewTableRow_error_400")} ${error.data.message[0]}`
        );
    }
    setShowModalDisabled(false);
  };

  const modalDeadHandler = async () => {
    let DEAD_STATUS = 100;

    const hasBeenUpdated = await patchStatusAndDeleted({
      data: {
        userId: data?.id,
        status: DEAD_STATUS,
      },
    });

    if (
      "data" in hasBeenUpdated &&
      hasBeenUpdated?.data?.meta?.status === "success"
    ) {
      toast.success("Konto zmarłego zablokowane");
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_userViewTableRow_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_userViewTableRow_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_userViewTableRow_error_400")} ${error.data.message[0]}`
        );
    }
    setShowModalDead(false);
  };

  const modalDeleteHandler = async () => {
    const settingsJSON = data?.settings;
    const settings = JSON.parse(settingsJSON);

    if (
      settings?.Wskazanie_na_opiekuna__c &&
      settings?.Wskazanie_podopiecznego__c
    ) {
      await deleteGuardianAndPatient(data?.id, "both");
    } else if (settings?.Wskazanie_na_opiekuna__c) {
      await deleteGuardianAndPatient(data?.id, "guardian");
    } else if (settings?.Wskazanie_podopiecznego__c) {
      await deleteGuardianAndPatient(data?.id, "patient");
    }

    const hasBeenUpdated = await deleteAccount(data?.id);

    if (
      "data" in hasBeenUpdated &&
      hasBeenUpdated?.data?.meta?.status === "success"
    ) {
      toast.success("Konto zostało usunięte");
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_userViewTableRow_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_userViewTableRow_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_userViewTableRow_error_400")} ${error.data.message[0]}`
        );
    }
    setShowModalDelete(false);
  };

  const modalActiveEmailHandler = async () => {
    if (data.enabled === 1) {
      return;
    }
    const hasBeenUpdated = await activeUserEmail({
      data: {
        userId: data?.id,
        enabled: 1,
      },
    });
    if (
      "data" in hasBeenUpdated &&
      hasBeenUpdated?.data?.meta?.status === "success"
    ) {
      toast.success("E-mail został actywowany");
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_userViewTableRow_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_userViewTableRow_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_userViewTableRow_error_400")} ${error.data.message[0]}`
        );
    }
    setShowModalEmailActive(false);
  };
  const modalRevertDeletion = async () => {
    if (!data?.settings) {
      console.error("Błąd: brak ustawień w danych.");
      return;
    }
    const deleteStatus = JSON.parse(data.settings);
    let sfKeyToDeleteAccount: SfKeyToDeleteAccount = {};
    if (deleteStatus.Do_usuni_cia__c) {
      deleteStatus.Do_usuni_cia__c = false;
      sfKeyToDeleteAccount = { Do_usuni_cia__c: false };
    }
    if (deleteStatus.Do_usuni_cia_all__c) {
      deleteStatus.Do_usuni_cia_all__c = false;
      sfKeyToDeleteAccount = { Do_usuni_cia_all__c: false };
    }
    if (Object.keys(sfKeyToDeleteAccount).length > 0) {
      await patchSfAccount({
        userId: String(data?.id),
        data: sfKeyToDeleteAccount,
      });
      const hasBeenUpdated = await changeAccountStatus({
        data: {
          userId: data?.id,
          status: ACCOUNT_TO_BE_DELETED_STATUS,
        },
      });
      if (
        "data" in hasBeenUpdated &&
        hasBeenUpdated?.data?.meta?.status === "success"
      ) {
        toast.success("Usunięcie zostało cofnięte");
      } else if ("error" in hasBeenUpdated) {
        const error = hasBeenUpdated.error as TranslationSubmitError;
        if (error.data.statusCode === 500)
          toast.error(t("toast_userViewTableRow_error_500"));

        if (error.data.statusCode === 404)
          toast.error(t("toast_userViewTableRow_error_404"));

        if (error.data.statusCode === 400)
          toast.error(
            `${t("toast_userViewTableRow_error_400")} ${error.data.message[0]}`
          );
      }
    }
    setShowModalRevertDeletion(false);
  };
  const modalEnabledHandler = async () => {
    await patchStatusAndDeleted({
      data: {
        userId: data?.id,
        enabled: data?.enabled === 1 ? 0 : 1,
      },
    });
    setShowModalEnabled(false);
  };
  const modalRestartMailHandler = async () => {
    const userId = data?.id;

    if (!userId) {
      console.error("No userId provided");
      return;
    }

    const resetPasswordResponse = await resetUserPassword({ userId });

    if (
      "data" in resetPasswordResponse &&
      resetPasswordResponse?.data?.meta?.status === "success"
    ) {
      toast.success("Link restartu hasła został wysłany.");
    } else if ("error" in resetPasswordResponse) {
      const error = resetPasswordResponse.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_userViewTableRow_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_userViewTableRow_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_userViewTableRow_error_400")} ${error.data.message[0]}`
        );
    }
    setShowModalRestart(false);
  };

  const getStatusLabel = (status: number) => {
    const STATUSES = [
      {
        value: 0,
        label: "Nieaktywne",
      },
      {
        value: 1,
        label: "Aktywne",
      },
      {
        value: 10,
        label: "Zablokowany",
      },
      {
        value: 90,
        label: "Do usunięcia",
      },
      {
        value: 100,
        label: "Usunięty",
      },
      {
        value: 200,
        label: "Zanonimizowany",
      },
    ];

    const statusObject = STATUSES.find((item) => item.value === status);

    return statusObject ? statusObject.label : "Nieznany status";
  };

  const checkFilledPolls = () => {
    let isAnyPollFilled = false;
    const localSettingsParsed = data?.localSettings
      ? JSON.parse(data?.localSettings)
      : null;
    const settingsParsed = data?.settings ? JSON.parse(data?.settings) : null;
    const userRoles = data?.roles?.map((role) => role?.role?.role);
    userRoles?.forEach((role) => {
      if (role !== "patient") {
        if (!!localSettingsParsed?.[`${role}_poll_Id`]) {
          isAnyPollFilled = true;
        }
      } else {
        if (!!settingsParsed?.["pollID__c"]) {
          isAnyPollFilled = true;
        }
      }
    });
    return isAnyPollFilled ? "tak" : "nie";
  };
  return (
    <>
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz ${
          data.status === 1 ? "dezaktywować" : "aktywować"
        } konto?`}
        smallText={`użytkownik: ${data.name}, e-mail: ${data.email}`}
        type="confirm"
        show={showModalStatus}
        setShow={() => setShowModalStatus(false)}
        confirmAction={() => modalStatusHandler()}
      />
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz ${
          data.status === 1 ? "zablokować" : "odblokować"
        } konto?`}
        smallText={
          data.status === 1
            ? `użytkownik: ${data.name}, e-mail: ${data.email}`
            : "Pamiętaj, żeby aktywować pozostałe statusy"
        }
        type="confirm"
        show={showModalDisabled}
        setShow={() => setShowModalDisabled(false)}
        confirmAction={() => modalDisabledHandler()}
      />
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz zresetować hasło?`}
        smallText={`użytkownik: ${data.name}, email: ${data.email}`}
        type="confirm"
        show={showModalRestart}
        setShow={() => setShowModalRestart(false)}
        confirmAction={() => modalRestartMailHandler()}
      />
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz ${
          data.enabled === 1 ? "dezaktywować" : "aktywować"
        } konto?`}
        smallText={`użytkownik: ${data.name}, e-mail: ${data.email}`}
        type="confirm"
        show={showModalEnabled}
        setShow={() => setShowModalEnabled(false)}
        confirmAction={() => modalEnabledHandler()}
      />
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy użytkownik zmarł?`}
        smallText={
          data.deleted === 0
            ? `Jeżeli tak potwierdź dezaktywacje konta użytkownika: ${data.name}, e-mail: ${data.email}`
            : "Pamiętaj, żeby aktywować pozostałe statusy"
        }
        type="confirm"
        show={showModalDead}
        setShow={() => setShowModalDead(false)}
        confirmAction={() => modalDeadHandler()}
        size="lg"
      />
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz usunąć to konto?`}
        smallText={
          data.deleted === 0
            ? `Konto użytkownika: ${data.name}, e-mail: ${data.email}`
            : "Pamiętaj, żeby aktywować pozostałe statusy"
        }
        type="confirm"
        show={showModalDelete}
        setShow={() => setShowModalDelete(false)}
        confirmAction={() => modalDeleteHandler()}
        size="lg"
      />
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz cofnąć usuwanie konta?`}
        smallText={
          data.deleted === 0
            ? `Konto użytkownika: ${data.name}, e-mail: ${data.email}`
            : "Pamiętaj, żeby aktywować pozostałe statusy"
        }
        type="confirm"
        show={showModalRevertDeletion}
        setShow={() => setShowModalRevertDeletion(false)}
        confirmAction={() => modalRevertDeletion()}
        size="lg"
      />
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz aktywować konto?`}
        smallText={
          data.deleted === 0
            ? `Konto użytkownika: ${data.name}, e-mail: ${data.email}`
            : "Pamiętaj, żeby aktywować pozostałe statusy"
        }
        type="confirm"
        show={showModalEmailActive}
        setShow={() => setShowModalEmailActive(false)}
        confirmAction={() => modalActiveEmailHandler()}
        size="lg"
      />
      <tr
        aria-controls="example-collapse-text"
        className={`  ${styles.mainTr}`}
      >
        <td
          className={`container-fluid ${data.deleted && styles.bg} ${
            styles.mainTd
          }`}
          colSpan={4}
        >
          <Row className="pb-2 bg-transpatent">
            <Col xs={2} className="text-dark-blue text-break">
              {data.id}
            </Col>
            <Col xs={3} className="text-dark-blue text-break">
              {data.name}
            </Col>
            <Col xs={4} className="text-dark-blue text-break">
              {data.email}
            </Col>
            <Col xs={2} className="text-dark-blue text-break">
              {data.enabled ? "Potwierdzone" : "Niepotwierdzone"}
            </Col>

            <Col className="d-flex justify-content-end align-content-start gap-3 text-dark-blue">
              <Button
                variant={isActive ? "dark" : "transparent"}
                className={`d-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
                  isActive
                    ? "text-light bg-dark"
                    : "text-dark-blue bg-transparent border-0"
                }`}
                onClick={() => setIsActive((prevState) => !prevState)}
              >
                <Arrow
                  className={isActive ? styles.arrowUp : styles.arrowDown}
                />
              </Button>
            </Col>
          </Row>
          {isActive && (
            <Row>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>AuthId: </span>
                <span className={"fw-semibold"}>{data.authId}</span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Utworzono: </span>
                <span className={"fw-semibold"}>
                  {dateEmbellishment(data.createdAt, "-")}
                </span>
              </Col>
              {/* <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Konto zablokowane: </span>
                <span className={"fw-semibold"}>
                  {data.deleted ? "tak" : "nie"}
                </span>
              </Col> */}
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Status: </span>
                <span className={"fw-semibold"}>
                  {getStatusLabel(data.status)}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Wypełniona ankieta: </span>
                <span className={"fw-semibold"}>{checkFilledPolls()}</span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Ostatnia zmiana: </span>
                {data.updateAt ? (
                  <span className={"fw-semibold"}>
                    {dateEmbellishment(data.updateAt, "-")}
                  </span>
                ) : (
                  <span className={"fw-semibold"}>-</span>
                )}
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>ID karty kodu: </span>

                <span className={"fw-semibold"}>
                  {data?.cardCode?.id ? data?.cardCode?.id : "-"}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"mb-2 text-dark-blue"}>
                <span>Numer karty kodu: </span>
                <span className={"fw-semibold"}>
                  {data?.cardCode?.number ? data?.cardCode?.number : "-"}
                </span>
              </Col>
              {data.deleted === 1 ? (
                <div className={"d-flex justify-content-between"}>
                  <span className={"text-grey-2 fs-14 my-3"}>
                    Konto zostało usunięte
                  </span>
                  <div>
                    <Button
                      className={"btn-transparent fs-14 text-dark-blue"}
                      onClick={() => setShowModalDisabled(true)}
                    >
                      Przywróć konto
                    </Button>
                  </div>
                </div>
              ) : (
                <Col className={"d-flex flex-column gap-3 my-3"}>
                  <div className={"d-flex justify-content-between"}>
                    <p className={"text-grey-2 fs-14"}>
                      Uprawnienia/role użytkownika
                    </p>
                    <Button
                      variant={isActiveRole ? "dark" : "transparent"}
                      className={`d-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
                        isActiveRole
                          ? "text-light"
                          : `text-dark-blue bg-transparent border-0`
                      }`}
                      onClick={() => setIsActiveRole((prev: boolean) => !prev)}
                    >
                      <PlusIcon
                        className={
                          isActiveRole ? styles.rotate : styles.noRotate
                        }
                      />
                    </Button>
                  </div>
                  {isActiveRole && <AdminUserRoleForm userId={data.id} />}
                  {data.roles?.map(
                    // eslint-disable-next-line
                    (value) => {
                      if (value.role.status > 0) {
                        return (
                          <Col
                            key={value.id}
                            className={"col-12 border border-1 rounded-2"}
                          >
                            <AdminUserRoleEntity
                              data={value}
                              userId={data.id}
                            />
                          </Col>
                        );
                      }
                    }
                  )}
                </Col>
              )}
            </Row>
          )}
          {data.status !== 100 && data.status !== 200 && (
            <Row className={`bg-transparent ${styles.hoverRow}`}>
              <Col>
                <Breadcrumb
                  data={breadcrumbData}
                  userStatus={data.enabled}
                  status={data.status}
                />
              </Col>
            </Row>
          )}
        </td>
      </tr>
      <ChangePasswordModal
        show={showChangePasswordModal}
        setShow={setShowChangePasswordModal}
      />
      <ChangePasswordWithAdminModal
        userId={data.id}
        show={showChangeUserPasswordModal}
        setShow={setShowChangeUserPasswordModal}
      />
    </>
  );
};
