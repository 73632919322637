import { Button, Container } from "react-bootstrap";
import { useState } from "react";
import TextInput from "components/atoms/TextInput/TextInput";
import { SubmitHandler, useForm } from "react-hook-form";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import GuardiansAndPatientsListElement from "components/molecules/GuardiansAndPatientsListElement/GuardiansAndPatientsListElement";
import {
  UserVerifyTypeEnum,
  VerificationData,
  useDeleteUserGuardiansAndPatientsMutation,
  useGetUserGuardiansAndPatientsQuery,
  useLazyGetUsersQuery,
  usePostUsersVerifyMutation,
  usePostImpersonateMutation,
  usePatchUserSFContactMutation,
  useGetUserQuery,
  useGetSfContactQuery,
} from "redux/services/usersApi";
import { toast } from "react-toastify";
import { RootState } from "redux/store/index";
import { useSelector } from "react-redux";
import { isRoleInUse } from "Helpers/Roles/isRoleInUse";
import style from "./GuardiansAndPatientsPanel.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { NAV_PATH } from "../Navigation/navigationData";
import { usePatchSFContactMutation } from "redux/services/salesforceApi";

const GuardiansAndPatientsPanel = ({
  text,
  type,
  className,
}: GuardiansAndPatientsPanelProps) => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const { data: guardiansAndPatientsData } =
  useGetUserGuardiansAndPatientsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const guardianOrPatientUserId: string =
    guardiansAndPatientsData?.data?.guardians?.length !== 0
      ? guardiansAndPatientsData?.data?.guardians?.[0]?.guardianId?.id?.toString()
      : guardiansAndPatientsData?.data?.patients?.[0]?.patientId?.id?.toString();

  const { data: guardianOrPatientData } = useGetUserQuery(
    { userId: guardianOrPatientUserId },
    { skip: !guardianOrPatientUserId }
  );
  const { data: guardianOrPatientSFData } = useGetSfContactQuery(
    { userId: guardianOrPatientUserId },
    { skip: !guardianOrPatientUserId }
  );
  const [removeGuardianOrPatient] = useDeleteUserGuardiansAndPatientsMutation();
  const [postUsersVerify] = usePostUsersVerifyMutation();
  const [postImpersonate] = usePostImpersonateMutation();
  const [getUser] = useLazyGetUsersQuery();
  const [patchSf] = usePatchSFContactMutation();
  const [patchSfAccount] = usePatchUserSFContactMutation();
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [showAddGuardianForm, setShowAddGuardianForm] = useState(false);
  const [userId, setUserID] = useState<number>();
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<VerificationData>({
    defaultValues: {
      id: userId,
      email: "",
      type:
        type === "patient"
          ? UserVerifyTypeEnum.GUARDIAN_PATIENT
          : UserVerifyTypeEnum.PATIENT_GUARDIAN,
    },
  });

  const { t } = useTranslation();

  const cancelForm = () => {
    setError("");
    setShowAddGuardianForm(false);
    reset();
  };

  type SettingKey = "Wskazanie_na_opiekuna__c" | "Wskazanie_podopiecznego__c";

  const deleteGuardianOrPatient = async (authId: string, id: number) => {
    let settingKeyMain: SettingKey;
    let settingKeyAlt: SettingKey;
    let roleContent: string;

    if (type === "guardian") {
      settingKeyMain = "Wskazanie_na_opiekuna__c";
      settingKeyAlt = "Wskazanie_podopiecznego__c";
      roleContent = "opiekuna";
    } else {
      settingKeyMain = "Wskazanie_podopiecznego__c";
      settingKeyAlt = "Wskazanie_na_opiekuna__c";
      roleContent = "podopiecznego";
    }

    await patchSfAccount({
      userId: id?.toString(),
      data: {
        [settingKeyAlt]: null,
      },
    });
    await patchSf({
      data: {
        [settingKeyMain]: null,
      },
    });

    const resultDelete = await removeGuardianOrPatient({
      data: { type: type === "guardian" ? "GUARDIAN" : "PATIENT", authId },
    });

    if ("data" in resultDelete) {
      toast.success(
        `${t(
          "toast_guardiansAndPatientsPanel_delete_role_success"
        )} ${roleContent}`
      );
    } else if ("error" in resultDelete) {
      const error = resultDelete?.error as TranslationSubmitError;

      switch (error.data.statusCode) {
        case 500:
          toast.error(
            t("toast_guardiansAndPatientsPanel_delete_role_error_500")
          );
          break;

        case 404:
          toast.error(`Nie znaleziono ${roleContent} o takim ID.`);
          break;

        case 400:
          toast.error(error.data.message);
          break;

        default:
          toast.error(t("toast_guardiansAndPatientsPanel_delete_role_error"));
          break;
      }
    }
  };

  const submitHandler: SubmitHandler<VerificationData> = async (data) => {
    const { data: userToAdd } = await getUser({ email: data?.email });
    const isUserExist = userToAdd?.data?.[0];

    const isUserHaveGuardian = userToAdd?.data?.[0]?.settings?.hasOwnProperty(
      "Wskazanie_na_opiekuna__c"
    )
      ? userToAdd?.data?.[0]?.settings["Wskazanie_na_opiekuna__c"]
      : false;
    const isUserHavePatient = userToAdd?.data?.[0]?.settings?.hasOwnProperty(
      "Wskazanie_podopiecznego__c"
    )
      ? userToAdd?.data?.[0]?.settings["Wskazanie_podopiecznego__c"]
      : false;

    const myEmail = (await getUser({ authId: authUser?.authId }))?.data
      ?.data?.[0]?.email;

    if (!isUserExist) {
      setError(t("toast_guardiansAndPatientsPanel_user_exist_error"));
      toast.error(t("toast_guardiansAndPatientsPanel_user_exist_error"));
      return;
    }

    if (isUserExist.email === myEmail) {
      setError("Adres e-mail musi być inny niż twój");
      toast.error(t("toast_guardiansAndPatientsPanel_email_error"));
      return;
    }

    if (isUserHaveGuardian && isRoleInUse("guardian", authUser)) {
      setError("Użytkownik ma przypisanego opiekuna");
      toast.error(t("toast_guardiansAndPatientsPanel_user_have_guardian"));
      return;
    }

    if (isUserHavePatient && isRoleInUse("patient", authUser)) {
      setError("Użytkownik ma przypisanego podopiecznego");
      toast.error(t("toast_guardiansAndPatientsPanel_user_have_patient"));
      return;
    }

    if (
      !isRoleInUse("patient", isUserExist) &&
      isRoleInUse("guardian", authUser) &&
      data.type === UserVerifyTypeEnum.GUARDIAN_PATIENT
    ) {
      setError("Użytkownik nie ma roli pacjenta");
      toast.error(
        t("toast_guardiansAndPatientsPanel_user_doesnt_have_role_patient_error")
      );
      return;
    }

    if (
      !isRoleInUse("guardian", isUserExist) &&
      isRoleInUse("patient", authUser) &&
      data.type === UserVerifyTypeEnum.PATIENT_GUARDIAN
    ) {
      setError("Użytkownik nie ma roli opiekuna");
      toast.error(
        t(
          "toast_guardiansAndPatientsPanel_user_doesnt_have_role_guardian_error"
        )
      );
      return;
    }

    setUserID(isUserExist?.id);

    const userVerifyResponse = await postUsersVerify({
      data: { ...data, id: isUserExist?.id },
    });

    setShowAddGuardianForm(false);

    const errorHandler = (errorStatusCode: number, errorMessage: string) => {
      switch (errorStatusCode) {
        case 500:
          toast.error(
            t("toast_guardiansAndPatientsPanel_user_verification_error_500")
          );
          break;

        case 400:
          toast.error(
            t("toast_guardiansAndPatientsPanel_user_verification_error_400")
          );
          break;

        default:
          toast.error(
            t("toast_guardiansAndPatientsPanel_user_verification_error")
          );
          break;
      }
    };
    if ("data" in userVerifyResponse) {
      if ("error" in userVerifyResponse.data) {
        const error = userVerifyResponse?.data as TranslationSubmitError;

        errorHandler(error.statusCode, error.message);
      } else {
        setShowPopupModal(true);
      }
    }
  };

  const loginAsGuardian = async (userId: number) => {
    await postImpersonate({ userId: userId.toString() });
    window.scrollTo(0, 0);
    navigate(`/${NAV_PATH.DASHBOARD}`);
    window.location.reload();
  };

  const isInactive = (
    userData: { data: { status: number } },
    sfData: { data: { Dead__c: boolean; Do_usuni_cia__c: boolean } }
  ) =>
    userData?.data?.status === 0 ||
    userData?.data?.status === 100 ||
    userData?.data?.status === 90 ||
    sfData?.data?.Dead__c ||
  //  sfData?.data?.Do_usuni_cia__c ||
    userData?.data?.status === 200;

  const getStatusMessage = (
    subject: GuardianOrPatient,
    type: "patient" | "guardian"
  ) => {
    switch (true) {
      case ((type === 'guardian' && subject?.guardianId.deleted) || (type === 'patient' && subject?.patientId.deleted)) === 1:
        return "Usunięty";
      case isInactive(guardianOrPatientData, guardianOrPatientSFData):
        return "Nieaktywny";
      case subject?.status === 0:
        return "W trakcie potwierdzenia";
      case type === "patient" && subject?.status === 1:
        return "Potwierdzono";
      case type === "guardian" && subject?.status === 1:
        return "Aktywny";
      default:
        return "Nieaktywny";
    }
  };

  return (
    <>
      <Container fluid className={`p-4 containerWithShadow ${className}`}>
        <h2
          className={`fw-600 fs-22 text-dark-blue mb-5 ${style.title}`}
          id={"GuardianPatientPanel"}
        >
          {type === "patient"
            ? t("widget_guardians_and_patients_panel_patients_title")
            : t("widget_guardians_and_patients_panel_guardian_title")}
        </h2>
        <p className="fw-400 fs-16 text-dark-blue">
          {type === "guardian" &&
          guardiansAndPatientsData?.data?.guardians?.length !== 0
            ? null
            : text}
        </p>
        {!showAddGuardianForm ? (
          <>
            {type === "patient" ? (
              <>
                {guardiansAndPatientsData?.data?.patients < 1 && (
                  <Button
                    variant="primary"
                    className="w-100 text-uppercase text-white"
                    onClick={() => setShowAddGuardianForm(true)}
                  >
                    {t("widget_guardians_and_patients_panel_patients_button")}
                  </Button>
                )}
                {guardiansAndPatientsData?.data?.patients?.map(
                  (patients: GuardianOrPatient, key: number) => (
                    <GuardiansAndPatientsListElement
                      key={key}
                      name={patients?.patientId?.name}
                      email={patients?.patientId?.email}
                      status={getStatusMessage(patients, "patient")}
                      avatarUrl={patients?.patientId?.avatar?.accessUrl}
                      type={type}
                      userAuthID={patients?.patientId?.authId}
                      deleteGuardianOrPatient={() =>
                        deleteGuardianOrPatient(
                          patients?.patientId?.authId ??
                            patients?.patientId?.email,
                          patients?.patientId?.id
                        )
                      }
                      loginAsGuardian={() =>
                        loginAsGuardian(patients?.patientId?.id)
                      }
                    />
                  )
                )}
              </>
            ) : (
              <>
                {guardiansAndPatientsData?.data?.guardians?.length === 0 && (
                  <div className="d-flex flex-column align-items-center justify-content-center p-4 mt-4 border">
                    <p className="text-grey fs-19 fw-400">
                      Brak opiekuna konta{" "}
                    </p>
                    <Button
                      variant="primary"
                      className="text-uppercase"
                      onClick={() => setShowAddGuardianForm(true)}
                    >
                      {t("widget_guardians_and_patients_panel_guardian_button")}
                    </Button>
                  </div>
                )}
                {guardiansAndPatientsData?.data?.guardians?.map(
                  (guardians: GuardianOrPatient, key: number) => (
                    <GuardiansAndPatientsListElement
                      key={key}
                      name={guardians?.guardianId?.name}
                      email={guardians?.guardianId?.email}
                      status={getStatusMessage(guardians, "guardian")}
                      type={type}
                      avatarUrl={guardians?.patientId?.avatar?.accessUrl}
                      userAuthID={guardians?.guardianId?.authId}
                      deleteGuardianOrPatient={() =>
                        deleteGuardianOrPatient(
                          guardians?.guardianId?.authId ??
                            guardians?.guardianId?.email,
                          guardians?.guardianId?.id
                        )
                      }
                    />
                  )
                )}
              </>
            )}
          </>
        ) : (
          <>
            <p className="fw-400 fs-16 text-dark-blue">
              {type === "guardian" ? "Opiekun " : "Podopieczny "}
              musi posiadać konto w Alivi
              {type === "patient" && " z rolą pacjenta"}.
            </p>
            <TextInput
              register={register("email", {
                required: { value: true, message: "Pole wymagane" },
                onChange: () => setError(""),
              })}
              className="fs-16 fw-400 flex-column w-100 mt-3 text-dark-blue"
              label={
                type === "guardian" ? "Email opiekuna" : "Email podopiecznego"
              }
              type="EventInput"
              error={errors.email?.message || error}
            />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-dark"
                className="w-auto mt-3 me-2 btn-outline-primary"
                onClick={cancelForm}
              >
                ANULUJ
              </Button>
              <Button
                variant="primary"
                className="w-auto mt-3 text-white"
                onClick={handleSubmit(submitHandler)}
              >
                {type === "guardian"
                  ? t("widget_guardians_and_patients_panel_guardian_button")
                  : t("widget_guardians_and_patients_panel_patients_button")}
              </Button>
            </div>
          </>
        )}
      </Container>
      <PopupModal
        type="info"
        text={`Aby dokończyć dodawanie ${
          type === "guardian" ? "opiekuna" : "podopiecznego"
        }, właściciel konta mailowego musi potwierdzić poprzez kliknięcie w wysłany link`}
        show={showPopupModal}
        setShow={setShowPopupModal}
        titleClasses="text-center fs-16 fw-600"
        smallText={t("modal_guardians_and_patients_panel_smallText")}
      />
    </>
  );
};

export default GuardiansAndPatientsPanel;