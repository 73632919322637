import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import ProfileHeader from "components/molecules/ProfileHeader/ProfileHeader";
import RolesDisplay from "./../../atoms/RolesDisplay/RolesDisplay";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "./../../../redux/store/index";
import DynamicPatientPoll from "components/organisms/DynamicPatientPoll/DynamicPatientPoll";
import styles from "./DashboardTemplate.module.scss";
import { SystemSetting } from "redux/Slice/system-settings-slice";
import PageContainer from "components/atoms/PageContainer/PageContainer";
import {
  useGetUserGuardiansAndPatientsQuery,
  usePutUserRoleMutation,
} from "redux/services/usersApi";
import {
  useLazyGetMeQuery,
  useGetMeQuery,
  usePatchMeMutation,
} from "redux/services/meApi";
import { toast } from "react-toastify";
import { UserRole, WidgetSetting } from "redux/Slice/user-slice";
import { widgetsSettingsDefault } from "Helpers/Widgets/widgetsSettingsDefault";
import { useWidgets } from "Helpers/Widgets/useWidgets";
import {
  useAssignDoctorMutation,
  useLazyGetDoctorQuery,
  usePatchDoctorMutation,
  usePostDoctorMutation,
} from "redux/services/doctorApi";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import { useGetSFContactQuery } from "redux/services/salesforceApi";
import { useGetSystemSettingsQuery } from "../../../redux/services/systemSettingsApi";
import { AUTH_DATA } from "../../../Helpers/Roles/authData";

const DashboardTemplate = () => {
  const { authUser, userRoles } = useSelector((state: RootState) => state.user);
  const rolesArray = authUser?.roles.map((roles: UserRole) => {
    return roles?.role?.role;
  });
  const { data: systemSettingsData } = useGetSystemSettingsQuery({});

  const systemSettings = systemSettingsData?.data;
  const { t } = useTranslation();
  const description = t("moja_roles_description").split(".");
  const [showRolesSelectScreen, setShowRolesSelectScreen] = useState(false);
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [showPoll, setShowPoll] = useState(false);
  const [pollID, setPollID] = useState<number | null>(null);
  const [postDoctor] = usePostDoctorMutation();
  const [updateDcotor] = usePatchDoctorMutation();
  const [assignDoctor] = useAssignDoctorMutation();
  const [getDoctor] = useLazyGetDoctorQuery();
  const { data: guardiandAndPatientsData } =
    useGetUserGuardiansAndPatientsQuery({});
  const { data: sfData } = useGetSFContactQuery({});
  // const [widgetsData, setWidgetsData] = useState<WidgetSetting[]>(
  //   authUser?.localSettings?.widgetsSettings
  //     ? authUser?.localSettings?.widgetsSettings
  //     : widgetsSettingsDefault
  // );
  const [widgetsData, setWidgetsData] = useState<WidgetSetting[]>(() => {
    if (authUser?.localSettings?.widgetsSettings) {
      const userWidgets = authUser.localSettings.widgetsSettings;
      return widgetsSettingsDefault.map(defaultWidget => {
        const userWidget = userWidgets.find(w => w.id === defaultWidget.id);
        return userWidget ? userWidget : defaultWidget;
      });
    }
    return widgetsSettingsDefault;
  });
  const [putRole] = usePutUserRoleMutation();
  const [patchMe] = usePatchMeMutation();
  const { data: getMeData } = useGetMeQuery({});
  const [getMe] = useLazyGetMeQuery();

  const closeDoctorModal = async () => {
    setShowPopupModal(false);
    const findKey = userRoles.find(
      (role: Role) => role.role === "doctor"
    ) as Role;
    await patchMe({
      data: {
        ...getMeData,
        settings: {
          ...getMeData?.settings,
          [findKey?.sfName]: true,
        },
      },
    });
    setShowRolesSelectScreen(false);
  };

  const pollSent = () => {
    setShowPoll(false);
  };

  const setRole = async (data: RoleTileData) => {
    const setting =
      systemSettings &&
      systemSettings.find(
        (systemSetting: SystemSetting) =>
          systemSetting?.context === data?.role?.role
      );
    const isRoleUpdated = await putRole({
      data: {
        userId: authUser.id,
        sendData: { roleId: data?.roleId.toString() },
      },
    });

    if (data?.role?.role === "doctor") {
      if (!data?.doctorId) {
        const isDoctorAdded = await postDoctor({
          data: {
            firstName: data?.firstName || "",
            lastName: data?.lastName || "",
            description: "",
            phone: data?.telephone,
            email: authUser?.email,
            userId: authUser?.id,
            degreeId: data?.degreeId,
            specializationIds: data?.specializationIds || [],
          },
        });

        if ("error" in isDoctorAdded) {
          const error = isDoctorAdded?.error as TranslationSubmitError;

          if (error.data.statusCode === 500)
            toast.error(t("toast_dashboardTemplate_add_doctor_error_500"));
          if (error.data.statusCode === 400) toast.error(error.data.message);
          toast.error(t("toast_dashboardTemplate_add_doctor_error"));
        }
      } else {
        const doctorData = await getDoctor(data?.doctorId);
        const isDoctorUpdated = await updateDcotor({
          id: data?.doctorId,
          data: {
            ...doctorData?.data,
            phone: data?.telephone,
          },
        });

        if ("error" in isDoctorUpdated) {
          const error = isDoctorUpdated?.error as TranslationSubmitError;

          if (error.data.statusCode === 500)
            toast.error(t("toast_dashboardTemplate_update_doctor_error_500"));
          if (error.data.statusCode === 400) toast.error(error.data.message);
          toast.error(t("toast_dashboardTemplate_update_doctor_error"));
        }

        const isDoctorAssigned = await assignDoctor({
          id: data?.doctorId,
        });

        if ("error" in isDoctorAssigned) {
          const error = isDoctorAssigned?.error as TranslationSubmitError;

          if (error.data.statusCode === 500)
            toast.error(t("toast_dashboardTemplate_assign_doctor_error_500"));
          if (error.data.statusCode === 400) toast.error(error.data.message);
          toast.error(t("toast_dashboardTemplate_assign_doctor_error"));
        }
      }
    }

    const findKey = userRoles.find(
      (role: Role) => role.role === data?.role?.role
    ) as Role;

    if ("data" in isRoleUpdated) {
      if (data?.role?.role === "doctor") {
        setShowPopupModal(true);
      } else {
        await patchMe({
          data: {
            ...getMeData,
            settings: {
              ...getMeData?.settings,
              [findKey?.sfName]: true,
            },
          },
        });
        toast.success(t("toast_dashboardTemplate_add_role_success"));
        await getMe({});
        setShowRolesSelectScreen(false);
      }
      if (data?.role?.role !== "patient") {
        setting && setPollID(parseInt(setting.value));
        setting?.value && setShowPoll(true);
      } else {
        authUser?.settings?.pollID__c &&
          setPollID(authUser?.settings?.pollID__c);
        authUser?.settings?.pollID__c && setShowPoll(true);
      }
    } else if ("error" in isRoleUpdated) {
      const error = isRoleUpdated?.error as TranslationSubmitError;

      if (error.data.statusCode === 500)
        toast.error(t("toast_dashboardTemplate_add_role_error_500"));

      if (error.data.statusCode === 400) toast.error(error.data.message);
      toast.error(t("toast_dashboardTemplate_add_role_error"));
      setShowRolesSelectScreen(false);
    }
  };

  const updatePoll = (roleName: string) => {
    window.scrollTo(0, 0);
    const setting =
      systemSettings &&
      systemSettings.find(
        (systemSetting: SystemSetting) => systemSetting?.context === roleName
      );
    if (!!setting?.value) {
      setPollID(parseInt(setting?.value));
      setShowPoll(true);
    }
  };

  const setPosition = async (direction: "up" | "down", id: number) => {
    let newArray: WidgetSetting[] = [];
    for (let i = 0; i < widgetsData.length; i++) {
      let record = { ...widgetsData[i] };
      newArray[i] = record;
    }
    newArray = newArray
      .filter((setting: WidgetSetting) =>
        Array.isArray(setting.forRole)
          ? setting.forRole.some((role) => rolesArray.includes(role))
          : rolesArray.includes(setting.forRole)
      )
      .sort((a, b) => (a.position > b.position ? 1 : -1));
    let selectedRecordIndex = newArray.findIndex((x) => x.id === id);
    let selectedRecord = newArray.find((x) => x.id === id);
    let oldRecord =
      newArray[
        direction === "up"
          ? selectedRecordIndex + 1
          : selectedRecordIndex !== 0
          ? selectedRecordIndex - 1
          : selectedRecordIndex + 1
      ];
    if (oldRecord && selectedRecord) {
      let temp = selectedRecord.position;
      selectedRecord.position = oldRecord.position;

      oldRecord.position = temp;
    }

    setWidgetsData(newArray);
  };

  const setActive = (active: boolean, id: number) => {
    let newArray: WidgetSetting[] = [];
    for (let i = 0; i < widgetsData.length; i++) {
      let record = { ...widgetsData[i] };
      newArray[i] = record;
    }
    let selectedRecord = newArray.find((x) => x.id === id);
    if (selectedRecord) {
      selectedRecord.isActive = active;
    }
    setWidgetsData(newArray);
  };
  // eslint-disable-next-line array-callback-return
  const widgets = useWidgets(widgetsData, updatePoll);

  useEffect(() => {
    (async () => {
      if (
        !authUser?.localSettings?.widgetsSettings ||
        authUser?.localSettings?.widgetsSettings?.length === 0
      ) {
        patchMe({
          data: {
            ...getMeData,
            localSettings: {
              ...getMeData?.localSettings,
              widgetsSettings: widgetsSettingsDefault,
            },
          },
        });
      }
      if (
        authUser?.roles?.length === 0 ||
        (authUser?.roles?.filter((role) => role?.role?.display === 1).length ===
          0 &&
          AUTH_DATA.ADMIN_ROLES.some((adminRole: string) =>
            rolesArray?.includes(adminRole)
          ))
      ) {
        setShowRolesSelectScreen(true);
      }
      userRoles?.map((role: Role) => {
        if (
          authUser?.settings[role?.sfName as keyof typeof authUser.settings]
        ) {
          const setting =
            systemSettings &&
            systemSettings.find(
              (systemSetting: SystemSetting) =>
                systemSetting?.context === role.role
            );
          if (
            role.role !== "patient" &&
            setting?.value &&
            authUser.localSettings?.[
              (role?.role + "_poll_Id") as keyof typeof authUser.localSettings
            ] !== parseInt(setting.value)
          ) {
            if (!!setting.value) {
              setPollID(parseInt(setting.value));
              setShowPoll(true);
            }
          }
          if (
            role.role === "patient" &&
            setting?.value &&
            authUser.settings?.pollID__c !== parseInt(setting.value)
          ) {
            if (!!setting.value) {
              setPollID(parseInt(setting.value));
              setShowPoll(true);
            }
          }
        }
        return true;
      });
    })();
    // eslint-disable-next-line
  }, [authUser, systemSettings, userRoles]);

  // const handleSubscription = async (subscribe: boolean) => {
  //   await patchMe({
  //     data: {
  //       ...authUser,
  //       localSettings: {
  //         ...authUser.localSettings,
  //         pushSubscription: subscribe,
  //       },
  //     },
  //   });
  // };

  const accountStatus = (() => {
    switch (true) {
      case authUser?.status === 0:
        return "blocked";
      case authUser?.status === 100 || sfData?.data?.Dead__c:
        return "dead";
      case authUser?.status === 90 || sfData?.data?.Do_usuni_cia__c:
        return "deleteProccess";
      case authUser?.status === 200:
        return "anonymization";
      default:
        return "active";
    }
  })();

  if (
    authUser?.status === 0 ||
    authUser?.status === 100 ||
    authUser?.status === 90 ||
    sfData?.data?.Dead__c ||
    sfData?.data?.Do_usuni_cia__c ||
    authUser?.status === 200
  ) {
    return (
      <PageContainer>
        <ProfileHeader
          isFirstVisit={true}
          type="dashboard"
          isAuthenticated={false}
          userData={authUser}
          setActive={setActive}
          setPosition={setPosition}
          widgetsData={widgetsData}
          handleChangeAvatar={() => setShowPoll(true)}
          guardian={guardiandAndPatientsData?.data?.guardians[0]}
          accountStatus={accountStatus}
        />
      </PageContainer>
    );
  }
  if (showRolesSelectScreen) {
    return (
      <PageContainer>
        <div className="d-flex flex-column align-items-center mx-3">
          <div className={styles.selectRoleContainer}>
            <h1 className="fw-600 text-dark-blue fs-39 p-5 text-center">
              {t("moja_roles_title").replace(
                "%imie%",
                `${!authUser?.name ? authUser?.firstName : authUser?.name}`
              )}
            </h1>
            <p className="fw-400 text-dark-blue fs-19 text-center mb-4">
              {description[0] + "."}
            </p>
            <p className="fw-400 text-dark-blue fs-14 text-center">
              {description[1]}
            </p>
          </div>
          <Container
            className={`containerWithShadow py-4 mt-5 ${styles.dashboardContainter}`}
          >
            <RolesDisplay setRole={setRole} />
          </Container>
        </div>
        <PopupModal
          show={showPopupModal}
          type="info"
          titleClasses="text-center fw-600 fs-16"
          text="Dziękujemy!"
          smallText="Poczekaj aż zweryfikujemy Twoje konto, aby móc przejść do edycji swojego profilu."
          setShow={closeDoctorModal}
        />
      </PageContainer>
    );
  }

  if (showPoll) {
    return (
      <PageContainer>
        <Container className={styles.dashboardContainter}>
          <DynamicPatientPoll pollId={pollID} setIsPollSend={pollSent} />
        </Container>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ProfileHeader
        isFirstVisit={true}
        type="dashboard"
        isAuthenticated={false}
        userData={authUser}
        setActive={setActive}
        setPosition={setPosition}
        widgetsData={widgetsData}
        handleChangeAvatar={() => setShowPoll(true)}
        guardian={guardiandAndPatientsData?.data?.guardians[0]}
        accountStatus={accountStatus}
      />
      <Container fluid className={`${styles.dashboardContainter}`}>
        {widgets && widgets.map((w) => w)}
      </Container>
      {typeof Notification !== "undefined" && (
        <>
          {/* PushNotifications Przeniesione na rozwój */}
          {/* <PushNotification
            handleSubscription={handleSubscription}
            isSubscribe={authUser?.localSettings?.pushSubscription}
          /> */}
          <PopupModal
            show={showPopupModal}
            type="info"
            titleClasses="text-center fw-600 fs-16"
            text="Dziękujemy!"
            smallText="Poczekaj aż zweryfikujemy Twoje konto, aby móc przejść do edycji swojego profilu."
            setShow={closeDoctorModal}
          />
        </>
      )}
    </PageContainer>
  );
};

export default DashboardTemplate;
