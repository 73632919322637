import BreadcrumbLink from 'components/atoms/BreadcrumbLink/BreadcrumbLink';
import BreadcrumbButton from '../../atoms/BreadcrumbButton/BreadcrumbButton';
import styles from './Breadcrumb.module.scss';

interface MyExactData {
  data: {
    name: string;
    path?: string;
    action?: () => void;
  }[];
  status?: number;
  userStatus?: number;
}

const Breadcrumb = ({ data, userStatus, status }: MyExactData) => {
  const defaultAction = () => {};
  return (
    <ul className={styles.breadcrumbList}>
      {data.map(({ name, path, action }, idx) => {
        if (name === 'Aktywuj E-mail' && userStatus === 1) {
          return '';
        }
        if (name === 'Cofnij usunięcie' && status !== 90) {
          return '';
        }
        return (
          <li key={idx} className={styles.breadcrumbListItem}>
            {path ? (
              <BreadcrumbLink to={path}>{name}</BreadcrumbLink>
            ) : (
              <BreadcrumbButton handler={action ?? defaultAction}>
                {name}
              </BreadcrumbButton>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumb;
