import { useForm } from "react-hook-form";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import s from "./PageEditFooterTemplate.module.scss";
import {
  useGetFooterLinksQuery,
  usePutFooterLinkCategoryMutation,
} from "redux/services/footerLinksApi";

const PageEditFooterTemplate = () => {
  const { data: footerLinksData } = useGetFooterLinksQuery({});
  const { register, watch, setValue } = useForm({
    defaultValues: { sections: footerLinksData && footerLinksData.data },
  });

  const [editModes, setEditModes] = useState<{ [key: number]: boolean }>({});
  const sections = watch("sections");
  const [putFooterLinkCategory] = usePutFooterLinkCategoryMutation();
  // const handleAddUrl = (sectionIndex: number) => {
  //   const currentSections = watch("sections");
  //   const newUrl = { urlTitle: "", url: "" };
  //   const updatedSections = [...currentSections];
  //   updatedSections[sectionIndex] = {
  //     ...updatedSections[sectionIndex],
  //     urls: [...updatedSections[sectionIndex].urls, newUrl],
  //   };

  //   setValue("sections", updatedSections);
  // };

  const handleSubmitData = async (data: any, sectionIndex: number) => {
    if (editModes[sectionIndex]) {
      await putFooterLinkCategory(data);
    }
    setEditModes((prev) => ({
      ...prev,
      [sectionIndex]: !prev[sectionIndex],
    }));
  };
  return (
    <footer>
      <h2 className={s.title}>Stopka nawigacyjna</h2>
      <Form className={s.formContainer}>
        <Accordion className={s.container} defaultActiveKey="0">
          {sections &&
            sections.map((section: any, sectionIndex: number) => {              
              if (section.id === 1) {
                return (
                  <Accordion.Item
                    eventKey={String(sectionIndex)}
                    key={sectionIndex}
                  >
                    <Accordion.Header>
                      <h3>{section.title}</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      {editModes[sectionIndex] ? (
                        <Form.Control
                          className={s.formLabel}
                          {...register(`sections.${sectionIndex}.title`)}
                          defaultValue={section.title}
                        />
                      ) : (
                        <p className={s.formLabel}>{section.title}</p>
                      )}
                      <div className={s.container}>
                        {section.urls.map((url: any, urlIndex: any) => (
                          <div key={urlIndex} className={s.listItem}>
                            <Form.Group className={s.formGroup}>
                              {editModes[sectionIndex] ? (
                                <div className={s.urlItem}>
                                  <p className={s.urlLabel}>
                                    wiersz {urlIndex + 1}
                                  </p>
                                  <Form.Control
                                    className={s.formLabel}
                                    {...register(
                                      `sections.${sectionIndex}.urls.${urlIndex}.urlTitle`
                                    )}
                                    defaultValue={url.urlTitle}
                                  />
                                </div>
                              ) : (
                                <div className={s.urlItem}>
                                  <p className={s.urlLabel}>
                                    wiersz {urlIndex + 1}:
                                  </p>
                                  <p className={s.formLabel}>{url.urlTitle}</p>
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        ))}
                      </div>

                      <Button
                        type="button"
                        className="mt-3"
                        onClick={() => {
                          handleSubmitData(section, sectionIndex);
                        }}
                      >
                        {editModes[sectionIndex]
                          ? "Save Section"
                          : "Edit Section"}
                      </Button>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              }
              if(section.id !== 1 && section.id !==5) {
              return (
                <Accordion.Item
                  eventKey={String(sectionIndex)}
                  key={sectionIndex}
                >
                  <Accordion.Header>
                    <h3>{section.title}</h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    {editModes[sectionIndex] ? (
                      <Form.Control
                        className={s.formLabel}
                        {...register(`sections.${sectionIndex}.title`)}
                        defaultValue={section.title}
                      />
                    ) : (
                      <p className={s.formLabel}>{section.title}</p>
                    )}
                    <div className={s.container}>
                      {section.urls.map((url: any, urlIndex: number) => (
                        <div key={urlIndex} className={s.listItem}>
                          <Form.Group className={s.formGroup}>
                            {editModes[sectionIndex] ? (
                              <div className={s.urlItem}>
                                <p className={s.urlLabel}>Tytuł:</p>
                                <Form.Control
                                  className={s.formLabel}
                                  {...register(
                                    `sections.${sectionIndex}.urls.${urlIndex}.urlTitle`
                                  )}
                                  defaultValue={url.urlTitle}
                                />
                              </div>
                            ) : (
                              <div className={s.urlItem}>
                                <p className={s.urlLabel}>Tytuł:</p>
                                <p className={s.formLabel}>{url.urlTitle}</p>
                              </div>
                            )}
                          </Form.Group>
                          <Form.Group className={s.formGroup}>
                            {editModes[sectionIndex] ? (
                              <div className={s.urlItem}>
                                <p className={s.urlLabel}>url:</p>
                                <Form.Control
                                  className={s.formLabel}
                                  {...register(
                                    `sections.${sectionIndex}.urls.${urlIndex}.url`
                                  )}
                                  defaultValue={url.url}
                                />
                              </div>
                            ) : (
                              <div className={s.urlItem}>
                                <p className={s.urlLabel}>url:</p>
                                <p className={s.formLabel}>{url.url}</p>
                              </div>
                            )}
                          </Form.Group>
                          {/* {editModes[sectionIndex] && (
                            <Button
                              onClick={() => {
                                const result = section.urls.filter((url)=>{
                                  
                                })
                                console.log(url,urlIndex,section);
                              }}
                            >
                              DELETE
                            </Button>
                          )} */}
                        </div>
                      ))}
                      {/* {editModes[sectionIndex] && (
                        <button
                          type="button"
                          className={`p-0 ${s.addBtn}`}
                          onClick={() => handleAddUrl(sectionIndex)}
                        >
                          <PlusIcon />
                        </button>
                      )} */}
                    </div>
                    <Button
                      type="button"
                      className="mt-3"
                      onClick={() => {
                        handleSubmitData(section, sectionIndex);
                      }}
                    >
                      {editModes[sectionIndex]
                        ? "Save Section"
                        : "Edit Section"}
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              )
            };
            if (section.id === 5) {
              return (
                <Accordion.Item
                  eventKey={String(sectionIndex)}
                  key={sectionIndex}
                >
                  <Accordion.Header>
                    <h3>{section.title}</h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    {editModes[sectionIndex] ? (
                      <Form.Control
                        className={s.formLabel}
                        {...register(`sections.${sectionIndex}.title`)}
                        defaultValue={section.title}
                      />
                    ) : (
                      <p className={s.formLabel}>{section.title}</p>
                    )}
                    <div className={s.container}>
                      {section.urls.map((url: any, urlIndex: any) => (
                        <div key={urlIndex} className={s.listItem}>
                          <Form.Group className={s.formGroup}>
                            {editModes[sectionIndex] ? (
                              <div className={s.urlItem}>
                                <p className={s.urlLabel}>
                                ©
                                </p>
                                <Form.Control
                                  className={s.formLabel}
                                  {...register(
                                    `sections.${sectionIndex}.urls.${urlIndex}.urlTitle`
                                  )}
                                  defaultValue={url.urlTitle}
                                />
                              </div>
                            ) : (
                              <div className={s.urlItem}>
                                <p className={s.urlLabel}>
                                ©
                                </p>
                                <p className={s.formLabel}>{url.urlTitle}</p>
                              </div>
                            )}
                          </Form.Group>
                        </div>
                      ))}
                    </div>

                    <Button
                      type="button"
                      className="mt-3"
                      onClick={() => {
                        handleSubmitData(section, sectionIndex);
                      }}
                    >
                      {editModes[sectionIndex]
                        ? "Save Section"
                        : "Edit Section"}
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              );
            }
            })}
        </Accordion>
      </Form>
    </footer>
  );
};

export default PageEditFooterTemplate;
