import styles from "./LoyaltyProgramServiceCardMyCode.module.scss";
import { Button, Card, Row } from "react-bootstrap";
import { CodeModal } from "../../organisms/CodeModal/CodeModal";
import QRCode from "react-qr-code";
import { dateEmbellishment } from "../../../Helpers/dateHelpers";
import React, { useState } from "react";
import { changeDateFormatNames } from "../../../Helpers/changeDateFormatNames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { ModalDecision } from "components/atoms/ModalDecision/ModalDecision";
import RadioInput from "components/atoms/RadioInput/RadioInput";
import { XmarkComponent } from "assets/Icons/Xmark";
import { toast } from "react-toastify";
import { useDeleteLoyaltyProgramsServiceSendCodeMutation } from "redux/services/loyaltyProgramApi";
interface Props {
  data: LoyaltyProgramServiceMyCode;
}

export const LoyaltyProgramServiceCardMyCode = ({ data }: Props) => {
  const [showCode, setShowCode] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteReason, setDeleteReason] = useState<string>("");
  const [xmarkColor, setXmarkColor] = useState<string>("#ed2369");
  const [deleteLoyaltyProgramService] = useDeleteLoyaltyProgramsServiceSendCodeMutation();
  const { t } = useTranslation();

  const handleDeleteBenefit = async () => {
    if (!deleteReason) {
      toast.error(t("toast_error_delete_reason_missing"));
      return;
    }
    try {
      const deleteResponse = await deleteLoyaltyProgramService({
        id: Number(data.loyaltyProgramService.id),
        reason: deleteReason,
        code: data.code
      });
  
      if (deleteResponse && "data" in deleteResponse) {
        toast.success(t("toast_loyalityProgramServiceCode_delete_success"));
        setShowDeleteModal(false); 
      } else {
        toast.error(t("toast_error_delete_failed"));
      }
    } catch (error) {
      console.error(error);
      toast.error(t("toast_error_delete_failed"));
    }
  };

  return (
    <Card as="article" className={`h-100 w-100 alivia-radius`}>
      <Card.Header
        className={`d-flex flex-column justify-content-between fs-19 alivia-radius
          fw-semibold lh-130 ls-3 text-dark-blue pb-3 bg-white ${styles.card}`}
      >
        <div className="d-flex justify-content-between align-items-start">
          <Link
            className={"h-100"}
            to={`/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${data.loyaltyProgramService.id}`}
            target={"_blank"}
          >
            <Card.Title
              className={`${styles.title} ${
                data.expireAt ? styles.titleHeight : " h-100 "
              }`}
            >
              {data.loyaltyProgramService.name}
            </Card.Title>
          </Link>
          <div style={{ position: "relative" }}>
            <Button
              className={`${styles.smallButton} d-flex align-items-center justify-content-center text-secondary bg-transparent`}
              size="sm"
              variant="outline-secondary"
              onClick={() => setShowDeleteModal(true)}
              onMouseEnter={() => setXmarkColor("#fff")}
              onMouseLeave={() => setXmarkColor("#ed2369")}
            >
              <XmarkComponent color={xmarkColor} size={18}/>
            </Button>
          </div>
        </div>
        {data.expireAt && (
          <Card.Subtitle
            className={"d-flex justify-content-between fs-12 fw-semibold mt-1"}
          >
            <span className={"text-grey-2"}>
              {t("widget_loyalty_your_benefits_expired_at_span")}
            </span>
            <span className={""}>
              {changeDateFormatNames(data.expireAt, false)}
            </span>
          </Card.Subtitle>
        )}
      </Card.Header>
      <Card.Body className={"p-0"}>
        <Card.Text as="div" className={"text-dark-blue fs-14 p-3"}>
          <Row className={"px-3"}>
            <Button
              variant={"outline-primary"}
              className={"fs-14 w-100 fw-semibold p-1"}
              onClick={() => setShowCode((prev) => !prev)}
            >
              {t("widget_loyalty_your_benefits_show_code_button")}
            </Button>
            <CodeModal
              show={showCode}
              handleClose={() => setShowCode((prev) => !prev)}
              label={"Kod"}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={data.code}
                viewBox={`0 0 256 256`}
              />
              <div
                className={`border border-1 text-center p-2 mt-4 alivia-radius`}
              >
                <span className={"fs-24 fw-semibold"}>{data.code}</span>
              </div>
              {data.usedAt && (
                <h5 className={"fw-semibold text-danger text-center mt-3"}>
                  {t("widget_loyalty_your_benefits_used_at_span")}{" "}
                  {dateEmbellishment(data.usedAt, "-")}
                </h5>
              )}
            </CodeModal>
          </Row>
        </Card.Text>
      </Card.Body>
      <ModalDecision
        show={showDeleteModal}
        size="lg"
        onHide={() => setShowDeleteModal(false)}
        title="Potwierdzenie usunięcia"
        handleExit={() => setShowDeleteModal(false)}
        handleAccept={handleDeleteBenefit}
        buttonExitLabel="Anuluj"
        buttonAcceptLabel="Potwierdź"
        disabledAccept={!deleteReason}
        buttonExitClass={styles.buttonExitClass}
      >
        <span className="fs-6 text-dark">Dlaczego chcesz usunąć kod pobranego benefitu?</span>
        <RadioInput
          label="Już skorzystałam/-em z oferty"
          value="1"
          customClasses="my-2 fs-15 text-dark"
          register={{
            name: "deleteReason",
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setDeleteReason(e.target.value),
            checked: deleteReason === "1",
          }}
        />
        <RadioInput
          label="Nie jestem już zainteresowana/-y ofertą, chcę przekazać kod innym osobom"
          value="2"
          customClasses="fs-15 text-dark"
          register={{
            name: "deleteReason",
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setDeleteReason(e.target.value),
            checked: deleteReason === "2",
          }}
        />
      </ModalDecision>
    </Card>
  );
};