import s from "./LandingFooter.module.scss";
import { useGetFooterLinksQuery } from "redux/services/footerLinksApi";
export default function LandingFooter() {
  const { data: footerLinksData, isLoading } = useGetFooterLinksQuery({});
  return (
    <footer className={s.footer}>
      <div className="container">
        <ul className={s.navList}>
          {!isLoading &&
            footerLinksData?.data.map(({ id, title, urls }: any) => {
              if (id === 1) {
                return (
                  <li className={s.listItem}>
                    <img
                      width="620"
                      height="169"
                      src="https://onkosnajper.pl/wp-content/uploads/2023/05/Alivia_onkofundacja_logo_white-3.png"
                      className={s.logo}
                      alt=""
                      decoding="async"
                      loading="lazy"
                    />
                    <ul>
                      {urls.map((url: any) => {
                        return (
                          <li className={s.companyInfo}>{url.urlTitle}</li>
                        );
                      })}
                    </ul>
                  </li>
                );
              }
              if (id !== 1 && id !== 5) {
                return (
                  <li className={s.listItem} key={id}>
                    <h5 className={s.title}>{title}</h5>
                    <ul>
                      {urls.map(({ url, urlTitle }: any, index: number) => (
                        <li className={s.navItem} key={index}>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="c-footer-nav__link text-decoration-none text-white"
                          >
                            {urlTitle}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              }
              if(id === 5){
                return<li className={s.listItem}>
                <div className={s.navItem}>
                  <div className={s.socialListItem}>
                    <a
                      href="https://www.facebook.com/FundacjaAlivia/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        width="30"
                        height="30"
                        src="https://onkosnajper.pl/wp-content/uploads/2023/06/f.png"
                        className="attachment-full size-full"
                        alt=""
                        decoding="async"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://twitter.com/fundacja_alivia"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        width="30"
                        height="30"
                        src="https://onkosnajper.pl/wp-content/uploads/2023/06/t.png"
                        className="attachment-full size-full"
                        alt=""
                        decoding="async"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/user/aliviafundacja"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        width="30"
                        height="30"
                        src="https://onkosnajper.pl/wp-content/uploads/2023/06/y.png"
                        className="attachment-full size-full"
                        alt=""
                        decoding="async"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/aliviaonkofundacja/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        width="30"
                        height="30"
                        src="https://onkosnajper.pl/wp-content/uploads/2023/06/i.png"
                        className="attachment-full size-full"
                        alt=""
                        decoding="async"
                        loading="lazy"
                      />
                    </a>
                  </div>
                  ©{urls[0].urlTitle} Alivia Onkofundacja
                  <br />
                  Dodajemy odwagi
                  <br />
                  chorym na raka
                  <br />
                  Więcej o nas:
                  <br />
                  <a className={s.aliviaLink} href="https://alivia.org.pl">
                    alivia.org.pl
                  </a>
                  <p>
                    <br />
                    Wykonawca:
                    <br />
                    <a href="https://atsolutions24.com" target="_blank">
                      Art-Tech Solutions
                    </a>
                  </p>
                </div>
              </li>
              }
            })}

        </ul>
      </div>
    </footer>
  );
}
