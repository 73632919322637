import Modal from "react-bootstrap/Modal";
import React from "react";
import { Button } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/types";

interface Props {
  show: boolean;
  title: string;
  children: React.ReactNode;
  onHide: () => void;
  headerClass?: string;
  bodyClass?: string;
  size?: "sm" | "lg" | "xl";
  disabledAccept?: boolean;
  handleExit: () => void;
  handleAccept: () => void;
  buttonExitClass?: string;
  buttonAcceptClass?: string;
  ExitVariant?: ButtonVariant;
  AcceptVariant?: ButtonVariant;
  buttonExitLabel: string;
  buttonAcceptLabel: string;
  closeButton?: boolean;
  customSizeClass?: string;
}

export const ModalDecision = ({
  show,
  children,
  onHide,
  title,
  bodyClass,
  headerClass,
  size,
  handleExit,
  handleAccept,
  disabledAccept = false,
  buttonExitClass = "",
  buttonAcceptClass = "",
  ExitVariant = "outline-secondary",
  AcceptVariant = "primary",
  buttonExitLabel,
  buttonAcceptLabel,
  closeButton = true,
  customSizeClass,
}: Props) => {
  return (
    <Modal
      size={size ?? "xl"}
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      className={customSizeClass}
    >
      <Modal.Header
        className={"border-0 text-dark-blue"}
        closeButton={closeButton}
      >
        <Modal.Title className={headerClass}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={bodyClass}>{children}</Modal.Body>
      <Modal.Footer className={"border-0 text-dark-blue"}>
        <Button
          className={buttonExitClass}
          variant={ExitVariant}
          onClick={handleExit}
        >
          {buttonExitLabel}
        </Button>
        <Button
          className={buttonAcceptClass}
          onClick={handleAccept}
          disabled={disabledAccept}
          variant={AcceptVariant}
        >
          {buttonAcceptLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
