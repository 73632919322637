import * as React from "react"
import { SVGProps } from "react"

interface XmarkComponentProps extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: number;
}

export const XmarkComponent = ({
  color = "#003B50",
  size = 18,
  ...props
}: XmarkComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m1 1 16 16m0-16L1 17"
    />
  </svg>
)
