import { footerLinks } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["FooterLinks"],
});

export const footerLinksApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getFooterLinks: builder.query({
      query: () => {
        return {
          url: `${footerLinks}/links`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["FooterLinks"],
    }),
    postFooterLinkCategory: builder.mutation({
      query: ( data : any) => {
        return {
          url: `${footerLinks}/create`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
          invalidatesTags: ["FooterLinks"],
        };
      },
    }),
    putFooterLinkCategory: builder.mutation({
      query: (data) => {
        return {
          url: `${footerLinks}/update/${data.id}`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
          invalidatesTags: ["FooterLinks"],
        };
      },
    }),
  }),
});

export const { useGetFooterLinksQuery, usePostFooterLinkCategoryMutation,usePutFooterLinkCategoryMutation } =
  footerLinksApi;
