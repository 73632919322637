import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const loadResources = async (locale: string) => {
  const url = `${process.env.REACT_APP_EXTERNAL_LINK_STATIC}/translation/moja_pl.json`;

  console.log("Fetching translation from:", url);

  try {
    const response = await fetch(url, {
      method: "GET",
      cache: "no-cache",
    });

    if (!response.ok) {
      throw new Error(`Error fetching translations: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Translation loading failed:", error);
    return null;
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      request: async (
        _options: any,
        _url: string,
        _payload: any,
        callback: (
          param: null,
          status: { data?: Record<string, string>; status: number }
        ) => void
      ) => {
        try {
          const data = await loadResources("pl");
          if (data) {
            callback(null, { data, status: 200 });
          } else {
            callback(null, { status: 500 });
          }
        } catch (e) {
          console.error("Error in request function:", e);
          callback(null, { status: 500 });
        }
      },
      requestOptions: {
        cache: "no-cache",
      },
    },
    react: {
      useSuspense: false,
    },
    debug: false,
    load: "languageOnly",
    fallbackLng: ["pl"],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator"],
      caches: [],
    },
  });

export default i18n;
